import React from 'react';
import imageTwo from '../../assets/images/aboutUsImageTwo.png';
import imageThree from '../../assets/images/aboutUsImageThree.png';
import SectionTtitle from '../common/sectionTtitle';

const ClientsFeedback = () => {
  return (
    <div>
      <div className="relative pb-8 ">
        <img
          src={imageTwo}
          className="absolute inset-0 w-full h-full object-cover brightness-50"
          alt="thinkingImage"
        />
        <div className="relative z-10">
          <SectionTtitle
            className="pt-12"
            isWhiteText
            title="OUR CLIENTS FEEDBACK"
            description=" We would like to share our clients' feedbacks and share our
          thoughts"
          />

          <div className=" mx-auto grid gap-4 sm:grid-cols-3  px-14 rounded-lg m-10 ">
            <div className="bg-white  gap-2 pl-[0.5rem] lg:pl-[1.25rem] pr-4 rounded-md text-center flex justify-start items-center">
              <img src={imageThree} className=" sm:w-[55px] w-[40px]" alt="socialMedial_logo"/>
              <div className="">
                <h1 className="text-2xl font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
            <div className="bg-white  py-2 gap-2 pl-[0.5rem] lg:pl-[1.25rem] pr-4 rounded-md text-center  flex justify-start items-center">
              <img src={imageThree} className="sm:w-[55px] w-[40px]" alt="socialMedial_logo" />
              <div className="">
                <h1 className="text-2xl font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
            <div className="bg-white   gap-2 pl-[0.5rem] lg:pl-[1.25rem]  pr-4 rounded-md text-center row-span-2 flex justify-start items-center">
              <img src={imageThree} className="sm:w-[55px] w-[40px] " alt="socialMedial_logo" />
              <div className="">
                <h1 className="text-2xl font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
            <div className="bg-white   gap-2 pl-[0.5rem] lg:pl-[1.25rem]  pr-4 rounded-md  text-center row-span-2  flex justify-start items-center">
              <img src={imageThree} className="sm:w-[55px] w-[40px] " alt="socialMedial_logo" />
              <div className="">
                <h1 className="text-2xl font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
            <div className="bg-white  gap-2  pl-[0.5rem] lg:pl-[1.25rem]  pr-4 rounded-md text-center row-span-2 flex justify-start items-center">
              <img src={imageThree} className="sm:w-[55px] w-[40px] " alt="socialMedial_logo"/>
              <div className="">
                <h1 className="text-2xl font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
            <div className="bg-white  py-4 gap-2 pl-[0.5rem] lg:pl-[1.25rem]  pr-4 rounded-md text-center flex   justify-start items-center">
              <img src={imageThree} className="sm:w-[55px] w-[40px] " alt="socialMedial_logo" />
              <div className="">
                <h1 className="text-[25px] font-semibold text-[#000000] text-left">
                  Technology
                </h1>
                <p className="text-lg font-semibold text-left opacity-[0.4]">
                  Had a wonderful working experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsFeedback;
