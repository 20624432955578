import React from 'react';
import workingImg from '../../assets/aboutIntro/workImg.png';
import groupImg from '../../assets/aboutIntro/explainingImg.png';
import dicussionImg from '../../assets/aboutIntro/groupdiscussion.png';
import FocusImg from '../../assets/aboutIntro/focussingImg.png';
import SectionTtitle from '../common/sectionTtitle';

const Services = () => {
  return (
    <section id="service">
      <div className="mx-5 md:mx-32">
        <SectionTtitle
          className="mb-4 mt-10 sm:mt-20"
          title="Experience Excellence : Delve into Our Range of Services"
          description="VMA LABS stands as epitome of innovation in management and
          technology consulting.As industry pioneers, we offer a dynamic suite of
          systems integration and consulting services that elevate your
          operations to a new heights of efficiency and profitability."
        />
        <div className="mb-12 text-center text-1xl lg:text-2xl mt-4"></div>
      </div>
      <div className="w-[90%] mx-auto">
        <div className="flex flex-col sm:flex-row justify-center gap-4 mb-4">
          <div className="group relative overflow-hidden">
            <div className="relative">
              <img
                src={workingImg}
                alt="workingImg"
                className="w-full group-hover:scale-110 transition-all duration-300"
              />
              <div className="bg-black/50 h-full w-full absolute top-0" />
            </div>
            <div className="absolute bottom-0 lg:translate-y-[70%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <h1 className="font-semibold text-sm lg:text-2xl text-center text-white bottom-4 px-10 md:px-20">
                Your Partner for Excellence PCoE Certified
              </h1>
              <p className="hidden lg:block text-[20px] text-white text-center px-10 opacity-0 group-hover:opacity-100 transition-all duration-300 my-5">
                Our PCoE-certified team ensures expertise and capabilities to
                handle complex business needs, meeting SAP's highest standards.
                Experience excellence with us.
              </p>
            </div>
          </div>
          <div className="group relative overflow-hidden">
            <div className="relative">
              <img
                src={groupImg}
                alt="groupImg"
                className="w-full group-hover:scale-110 transition-all duration-300"
              />
              <div className="bg-black/50 h-full w-full absolute top-0" />
            </div>
            <div className="absolute bottom-0 lg:translate-y-[70%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <h1 className="font-semibold text-sm lg:text-2xl text-center text-white bottom-4 px-10 md:px-20">
                Drive Business Excellence with Our Dedicated Center of
                Excellence
              </h1>
              <p className="hidden lg:block text-[20px] text-white text-center px-10 opacity-0 group-hover:opacity-100 transition-all duration-300 my-5">
                50+ SAP experts at VMA Labs drive excellence with a dedicated
                Center of Excellence. We ensure compliance and foster continuous
                improvements for the highest standards.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-4 w-full">
          <div className="relative group overflow-hidden">
            <div className="relative">
              <img
                src={dicussionImg}
                alt="dicussionImg"
                className="w-full group-hover:scale-110 transition-all duration-300"
              />
              <div className="bg-black/50 h-full w-full absolute top-0" />
            </div>
            <div className="absolute bottom-0 lg:translate-y-[70%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <h1 className="font-semibold text-sm lg:text-2xl text-center text-white bottom-4 px-10 md:px-20">
                VMA Labs' Cutting-Edge Cloud Services
              </h1>
              <p className="hidden lg:block text-[20px] text-white text-center px-10 opacity-0 group-hover:opacity-100 transition-all duration-300 my-5">
                Boost productivity, streamline workflows, and reduce costs with
                our expert team and cutting-edge technologies. Scale and succeed
                in today's business landscape.
              </p>
            </div>
          </div>
          <div className="relative group overflow-hidden">
            <div className="relative">
              <img
                src={FocusImg}
                alt="FocusImg"
                className="w-full group-hover:scale-110 transition-all duration-300"
              />
              <div className="bg-black/50 h-full w-full absolute top-0" />
            </div>
            <div className="absolute bottom-0 lg:translate-y-[70%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <h1 className="font-semibold text-sm lg:text-2xl text-center text-white bottom-4 px-10 md:px-14">
                Elevate Customer Experience
              </h1>
              <p className="hidden lg:block text-[20px] text-white text-center px-10 opacity-0 group-hover:opacity-100 transition-all duration-300 my-5">
                VMA Labs' E-commerce Services. Engage customers uniquely, foster
                repeat business, and maximize your potential for success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
