import React from 'react';
import smileIcon from '../../assets/Icon/smileIcon.png';
import fileIcon from '../../assets/Icon/fileIcon.png';
import supportIcon from '../../assets/Icon/supportIcon.png';
import workerIcon from '../../assets/Icon/workerIcon.png';

const Statistics = () => {
  return (
    <div>
      <div className="md:px-16 py-5  ">
        <div className=" md:flex justify-evenly items-center bg-[#676767] flex-col-3 py-10 rounded-tl-[70px] rounded-br-[70px] ">
          <div className=" pb-9 md:pb-1">
            <div className="flex justify-center pb-2">
              <img src={smileIcon} alt="smileIcon" />
            </div>
            <p className="text-center text-xl text-[#FFFFFF] ">100+</p>
            <p className="text-center text-2xl font-semibold text-[#FFFFFF]">
              Happy clients
            </p>
          </div>
          <div className=" pb-9 md:pb-1">
            <div className="flex justify-center pb-2 ">
              <img src={fileIcon} alt="smileIcon" />
            </div>
            <p className="text-center text-xl text-[#FFFFFF] ">100+</p>
            <p className="text-center text-2xl font-semibold text-[#FFFFFF]">
              Projects
            </p>
          </div>
          <div className=" pb-9 md:pb-1">
            <div className="flex justify-center pb-2 ">
              <img src={supportIcon} alt="smileIcon" />
            </div>
            <p className="text-center text-xl text-[#FFFFFF] ">100+</p>
            <p className="text-center text-2xl font-semibold text-[#FFFFFF]">
              Hour Of Supports
            </p>
          </div>
          <div className=" pb-9 md:pb-1">
            <div className="flex justify-center pb-2 ">
              <img src={workerIcon} alt="smileIcon" />
            </div>
            <p className="text-center text-xl text-[#FFFFFF]">100+</p>
            <p className="text-center text-2xl font-semibold text-[#FFFFFF]">
              Hard Worker
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
