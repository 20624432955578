import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Slider from 'react-slick';
import FreeQuoteModal from './freeQuoteModal';

const IntroHome = ({ count }) => {
  const [isModalOpen, setisModalOpen] = useState(false);

  const openModal = () => {
    setisModalOpen(true);
  };
  const closeModal = () => {
    setisModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModalOpen]);

  const [changeText, setChangeText] = useState(false);
  useEffect(() => {
    setChangeText((prevState) => !prevState);
  }, [count]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
  };

  return (
    <div className="w-full sm:w-[94%] mx-auto">
      <div className="md:absolute bg-home bg-no-repeat bg-center bg-cover bg-opacity-75 sm:bg-none text-white top-0 sm:top-[55%] sm:translate-y-[-50%] pt-10 pb-5 sm:pt-5">
        <div className="sm:hidden absolute top-0 left-0 h-full w-full bg-black/40" />
        <div className="relative pt-24 p-8 md:p-[20px] xl:p-10 lg:pt-0 w-full md:w-[75%] xl:w-full">
          <div className="mb-5 xl:mb-6">
            <Slider {...settings}>
              <div className="text-[17px] lg:text-[25px] xl:text-[37px] my-10 md:my-7">
                <p className="font-normal text-white ">
                  Fuel your success, unlock opportunities, drive
                </p>
                <p className="font-bold text-white ">
                  expansion and achieve remarkable results with US
                </p>
              </div>
              <div className="text-[17px] lg:text-[25px] xl:text-[37px] my-12 md:my-7">
                <p className="font-normal text-white">
                  Propel your business and achieve new
                </p>
                <p className="font-bold text-white">
                  height with VMA's transformative solutions.
                </p>
              </div>
            </Slider>
          </div>

          <div className="absolute right-0 hidden">
            <div
              className={cx(
                'rounded-lg w-[7px] h-[28px] bg-white opacity-60 transition-all duration-500',
                {
                  'opacity-100 h-[13px]': changeText,
                }
              )}
            ></div>
            <div
              className={cx(
                'rounded-lg w-[7px] h-[28px] mt-2 bg-white opacity-60 transition-all duration-500',
                {
                  'opacity-100 h-[13px]': !changeText,
                }
              )}
            ></div>
          </div>
          <div className="xl:p-2 xl:pt-0 text-white text-[15px] md:text-[16px] lg:text-[19px] font-medium xl:w-3/5">
            Streamline processes, gain insights, and make data-driven decisions
            with VMA Labs. Our tailored technology propels success for
            businesses of all sizes. Embrace the future of business management,
            unlock growth opportunities, and thrive with us today.
          </div>
          <div className="xl:pl-2 pt-10 md:pt-2">
            <button
              onClick={openModal}
              className="bg-white border-white border-2px text-black text-[16px] md:text-[20px] font-medium py-3 px-7 transition-all duration-500 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px] !shadow-white/70"
            >
              Get A Quote
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <FreeQuoteModal isOpen={isModalOpen} onClose={closeModal} />
      )}
    </div>
  );
};

export default IntroHome;
