import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import success from '../../assets/images/success.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const ContactModal = ({ closeModal, contactModal }) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  let location = useLocation();

  const handleClickOutside = useCallback(
    (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    },
    [closeModal]
  );

  const handleEscape = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    if (contactModal) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [contactModal, closeModal, handleClickOutside, handleEscape]);

  useEffect(() => {
    navigate(`${location.pathname}/thank-you`);

    return () => {
      navigate(`${location.pathname}`);
    };
  }, []);

  return (
    <div className="flex justify-center items-center">
      <>
        {contactModal && (
          <div
            ref={modalRef}
            className="fixed bottom-32 md:bottom-1/2 bg-black drop-shadow-lg p-4 z-[120]"
          >
            <div
              className="flex justify-end text-[40px] text-white cursor-pointer"
              onClick={closeModal}
            >
              x
            </div>
            <div className="flex justify-center items-center">
              <img src={success} alt="success-icon" />
            </div>
            <div className="text-center w-full mx-auto text-white py-3">
              <p className="text-[20px] md:text-[24px]">
                Thank You for contacting us.
              </p>
              <p className="text-[20px] md:text-[24px] font-thin">
                We appreciate that you’ve taken time to write us. we’ll get back
                to you very soon.
              </p>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ContactModal;
