import React from 'react';
import AI_image from '../../../assets/AI_image.png';
import ERP from '../../../assets/ERP_image.png';
import CLIENT from '../../../assets/client_image.png';
import SOLUTION from '../../../assets/solution_image.png';
import key1 from '../../../assets/key1_image.png';
import key2 from '../../../assets/key2_image.png';
import key3 from '../../../assets/key3_image.png';
import key4 from '../../../assets/key4_image.png';

const featuresData = [
  {
    title: 'AI-powered Excellence',
    image: key1,
    description:
      'V-Sync adapts the power of Artificial Intelligence to provide users with a seamless experience to manage business efficiently. Our AI-powered excellence offers valuable insights, guesses about the future, and suggestions on what you should do. This helps you make smarter choices in how you run your business.',
    imageSrc: AI_image,
    alt: 'AI Image',
  },
  {
    title: 'Seamless Integration with ERP',
    image: key2,
    description:
      'Experience a new level of business management with seamless integration of V-sync with your current ERP systems, forming a united environment that boosts your business operations more efficiently. This will ensure you will have a complete overview of your business operation - All in one place.',
    imageSrc: ERP,
    alt: 'ERP Image',
  },
  {
    title: 'Redefining Vendor and External Client Management',
    image: key3,
    description:
      'Our AI-powered business management tool helps you manage and ensure hassle-free communication between Vendors and your business. In this way, you will make sure everyone communicates with each other efficiently - Ensuring there will be no misunderstandings and everyone can work together smoothly.',
    imageSrc: CLIENT,
    alt: 'Client Image',
  },
  {
    title: 'Better End-to-End Solutions',
    image: key4,
    description:
      'V-Sync is not just an ordinary tool, it’s your strategic partner that comes with a full package of business management solutions that help make your workflow smooth, make your processes work better, and overall help your business perform at its best level. In this way, you can enhance your overall business efficiency.In this way, you can enhance your overall business efficiency.',
    imageSrc: SOLUTION,
    alt: 'Solution Image',
  },
];

const Features = () => {
  return (
    <div className="bg-black">
      <div className="w-[80%] m-auto">
        <div className=" flex justify-center items-center">
          <p className="text-center text-[20px] lg:text-[40px] font-medium text-[#FFFFFF] p-3">
            Key Features of V-Sync
          </p>
        </div>
        <div className="flex justify-center items-center">
          <p className="text-center w-[90%] m-auto text-[20px] font-thin text-[#8F8F8F] p-3">
            Our smart business management tool is carefully crafted with
            features rich that allow you to manage your business the smart way.
          </p>
        </div>
        <div className="text-left">
          <p className="inline-block text-transparent text-[26px] font-[400] capitalize bg-clip-text bg-gradient-to-r from-[#7C09C3] via-[#86D8F2] to-[#F69400] pb-8">
            Key features
          </p>
        </div>
        <div className="grid lg:grid-cols-2 gap-4">
          {featuresData.map((feature, index) => (
            <div
              key={index}
              className="bg-[#121212] text-white p-5 rounded-2xl"
            >
              <div className="pb-6">
                <img src={feature.image} />
              </div>
              <p className="pb-2 text-[20px] md:text-[24px]">{feature.title}</p>
              <p className="w-[100%] text-[20px] text-[#AAAAAA] font-thin  mr-auto">
                {feature.description}
              </p>
              <div className="flex justify-end align-middle items-end pb-2">
                <img
                  src={feature.imageSrc}
                  className="w-[15%]"
                  alt={feature.alt}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
