import React from 'react';
import map1 from '../../../src/assets/images/map1.png';
import map2 from '../../../src/assets/images/map2.png';
import map3 from '../../../src/assets/images/map3.png';
import { Link } from 'react-router-dom';
import SectionTtitle from '../common/sectionTtitle';

const TalkSection = () => {
  const mapdata = [
    {
      place: 'HYDERABAD',
      Map: map1,
    },
    {
      place: 'GURUGRAM',
      Map: map2,
    },
    {
      place: 'BANGALORE',
      Map: map3,
    },
  ];
  return (
    <section id="talk" className="bg-[#FFA91B] p-6">
      <SectionTtitle
        className="pt-2"
        title="Let's Talk"
        description="Let's Connect and Make Magic Happen!"
      />
      <div className="p-6">
        <div className="border-t-0 border-l-0 sm:!border-t-[5px] sm:border-l-[5px] border-black grid grid-cols-1 md:grid-cols-2">
          <div className="border-b-0 sm:border-b-[5px] border-black sm:p-10">
            <h1 className="text-black text-[30px] sm:text-[35px] lg:text[50px] text-center">
              Lets Make It Happen
            </h1>
            <p className="text-black text-center text-sm sm:text-base lg:text-[26px] lg:leading-8 mt-5">
              Got a challenge or an idea? We're here, ready to listen! Visit our
              website at
              <Link to="/" className="underline">
                vmatechlabs.com
              </Link>
              to explore how we can turn your vision into reality.
            </p>
          </div>
          <div>
            <div className="grid gap-4 grid-cols-3 pl-2 pt-5">
              {Array.isArray(mapdata) &&
                mapdata.map(({ place, Map }) => (
                  <div key={place}>
                    <h1 className="text-black text-center text-xs sm:text-xl font-bold">
                      {place}
                    </h1>
                    <div className="border-2 border-black p-3">
                      <img src={Map} alt="map" className="w-full" />
                    </div>
                  </div>
                ))}
            </div>

            <div className="hidden sm:flex justify-end pt-10">
              <Link
                to="/contact"
                className="flex justify-center items-center bg-white  border-white border-2 text-black  text-[16px] md:text-[20px] font-medium py-4 px-8 transition-all duration-500 !shadow-white/70 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px]"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-left sm:text-center text-black text-sm sm:text-base lg:text-[22px] py-5">
        We can't wait to hear from you and walk on an exciting journey together!
      </div>
      <div className="flex sm:hidden justify-end pt-3">
        <Link
          to="/contact"
          className="flex justify-center items-center bg-white  border-white border-2 text-black  text-[16px] md:text-[20px] font-medium py-4 px-8 transition-all duration-500 !shadow-white/70 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px]"
        >
          Contact Us
        </Link>
      </div>
    </section>
  );
};

export default TalkSection;
