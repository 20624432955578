import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppContext } from './context';
import HomePage from './pages';
import ServicePage from './pages/services';
import Careers from './pages/careers';
import Explore from './pages/explorePage';
import AboutUs from './pages/about';
import PageNotFound from './pages/pageNotFound';
import PrivacyPage from './pages/privacy';
import ContactPage from './pages/contact';
import TermsPage from './pages/terms';
import AllSection from './sectionForms/Home/allSection';
import ScrollTop from './components/home/scrollTop';
import UnderMaintenance from './pages/underMaintenance';
import Cookies from './components/home/cookies';
import SitemapPage from './pages/sitemap';
import Vsync from './pages/products/vSync';
import EInvoice from './pages/eInvoice';
import Digitalsign from './pages/digitalsign';

function App() {
  const { pathname } = useLocation();
  const scrollTop = () => scroll.scrollToTop();
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    scrollTop();
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    // this function is not calling directly after refreshing so we've used setTimeout
    setTimeout(() => {
      scrollTop();
    }, 50);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="font-link">
      <AppContext.Provider value={scrollY}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="careers" element={<Careers />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="explore" element={<Explore />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="admin" element={<AllSection />} />
          <Route path="sitemap" element={<SitemapPage />} />
          <Route path="/under-maintainance" element={<UnderMaintenance />} />
          <Route path="e-invoice/*" element={<EInvoice />} />
          {/* all product routes */}
          <Route path="/products/vsync/*" element={<Vsync />} />
          <Route path="/digitalSign/*" element={<Digitalsign />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>

        <ScrollTop onClick={scrollTop} />
        <Cookies />
      </AppContext.Provider>
    </div>
  );
}

export default App;
