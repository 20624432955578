import About from '../../assets/images/about.svg';
import Achieved from '../../assets/images/achieved.svg';
import Brain from '../../assets/images/brain.svg';
import Career from '../../assets/images/career.svg';
import Eminence from '../../assets/images/eminence.svg';
import GetInTouch from '../../assets/images/getInTouch.svg';
import Quote from '../../assets/images/quote.svg';
import Ventures from '../../assets/images/ventures.svg';
import Standout from '../../assets/images/standout.svg';
import Work from '../../assets/images/work.svg';

// ids of all section
export const sections = [
  {
    id: 'home',
    title: 'GET A QUOTE',
    icon: Quote,
  },
  {
    id: 'service',
    title: 'EMINENCE',
    icon: Eminence,
  },
  {
    id: 'work',
    title: 'WORK PORTFOLIO',
    icon: Work,
  },
  {
    id: 'explore',
    title: 'BRAIN WAVE',
    icon: Brain,
  },
  {
    id: 'critical',
    title: 'VENTURES',
    icon: Ventures,
  },
  {
    id: 'about',
    title: 'ABOUT US',
    icon: About,
  },
  {
    id: 'career',
    title: 'CAREERS',
    icon: Career,
  },

  {
    id: 'projects',
    title: 'ACHIEVED',
    icon: Achieved,
  },
  {
    id: 'difference',
    title: 'STANDOUT',
    icon: Standout,
  },
  {
    id: 'talk',
    title: 'GET IN TOUCH',
    icon: GetInTouch,
  },
];
