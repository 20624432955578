import React from 'react';
import Layout from '../components/layout';
import Button from '../components/common/button';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/*" />
      </Helmet>
      <Layout>
        <div className="flex flex-col gap-3 w-full text-center min-h-[60vh] p-4 absolute top-[20%]">
          <h1 className="text-black text-4xl lg:text-6xl">
            There's nothing here...
          </h1>
          <p className="text-black sm:text-3xl">
            _may be the page you're looking for is not found or never existed.
          </p>
        </div>
        <Link
          to="/"
          className="absolute top-[57%] md:top-[46%] lg:top-[49%] xl:top-1/2  left-[28%] md:left-[40%] lg:left-[41%] xl:left-[45%]"
        >
          <Button className="text-white bg-black flex justify-center items-center rounded-full px-5 py-4">
            Back to home
          </Button>
        </Link>
      </Layout>
    </>
  );
};

export default PageNotFound;
