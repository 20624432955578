import React from 'react';
import FlipAnimation from './flipAnimation';

const WhyVmaSection = () => {
  return (
    <div>
      <div className="flex gap-5 md:w-[90%] mx-auto pl-[23px] my-3 items-center text-[13px] md:text-2xl text-[#000000] font-semibold">
        Why Vma Labs
        <div className="flex gap-3">
          <span className=" opacity-[0.3] flex align-middle">\</span>
          <span className=" opacity-[0.6]  flex align-middle">\</span>
          <span className=" opacity-[0.8]  flex align-middle">\</span>
        </div>
      </div>
      <div className="w-[90%] relative grid xl:grid-cols-2 gap-3 mx-auto mb-10">
        <FlipAnimation />
        <div className="flex flex-col justify-center items-center">
          <div className="text-black p-5 text-xl">
            We are awarded with the SAP Partner Center of Expertise (PCoE)
            Certification, which hands down the most rigorous compliance...
          </div>
        </div>
        <div className="w-full text-center text-[25px] md:text-[51px] xl:absolute  top-[58%] xl:top-[82%] lg:left-[12%] xl:left-0">
          <span className="xl:text-white"> Help us to b</span>uild your career
        </div>
      </div>
    </div>
  );
};

export default WhyVmaSection;
