import React, { useState } from 'react';
import infinitebg from '../../../assets/products/infinitebg.svg';
import { ReactComponent as VsyncDashboard } from '../../../assets/products/vsyncDashboard.svg';
import { ReactComponent as BudgetChartImage } from '../../../assets/products/totalBudgetChart.svg';
import { ReactComponent as InvoiceChartImage } from '../../../assets/products/InvoiceRecievedChart.svg';
import { ReactComponent as NegotiationChartImage } from '../../../assets/products/NegotiationChart.svg';
import { useCallback } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../context';
import cx from 'classnames';
import { Link } from 'react-scroll';

const BusinessTool = () => {
  const [count, setCount] = useState(0);
  const changeText = useCallback(() => {
    setTimeout(() => {
      setCount(count === 0 ? 1 : 0);
    }, 2000);
  }, [count]);

  const scrollY = useContext(AppContext);

  changeText();

  const imageTiltStyle = {
    scale: 'none',
    transform: `perspective(${
      130 + scrollY / 20
    }rem) translate3d(0px, ${-(scrollY <= 400
      ? scrollY / 1.7
      : 400 / 0.8)}px, ${40 - scrollY}px) rotateX(${
      scrollY <= 400 ? scrollY / 9 - 45 : 0
    }deg) scale(0.${999 - (scrollY <= 400 ? scrollY : 400) / 4}, 0.${
      scrollY <= 400 && 999 - scrollY / 4
    })`,
  };

  return (
    <div
      id="business"
      className="!bg-black overflow-hidden relative min-h-screen pt-36"
    >
      <div className="absolute hidden md:block rotation origin-center overflow-hidden -z-0">
        <img src={infinitebg} className="w-full h-full object-fill" />
      </div>
      <div className="z-10 relative">
        <div className="w-1/2 mx-auto">
          <div className="text-2xl lg:text-[29px] text-white text-center font-thin">
            {count ? (
              <>
                <p>The Only Smart Business</p>
                <p className="pt-3">Management Tool</p>
              </>
            ) : (
              <>
                <p>Manage Your Business Operation</p>
                <p className="pt-3">the smart way with V-Sync</p>
              </>
            )}
          </div>
          <div className="text-center text-[#B0B0B0] font-thin pt-4">
            With V-Sync, you can connect, collaborate, and manage your business
            with smart AI-powered technology, valuable insights, and vendor
            management.
          </div>
        </div>
        <Link to="contactus" smooth={true} duration={500}>
          <div className="flex justify-center items-center p-4">
            <button className="bg-white border-white border-2px text-black text-[16px] md:text-[20px] font-medium py-3 px-7 transition-all duration-500 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px] !shadow-white/70">
              Request Free Demo
            </button>
          </div>
        </Link>
        <div className="h-full mt-48">
          <div
            className="relative transition-all duration-[0.5s]"
            style={imageTiltStyle}
          >
            <VsyncDashboard className="mx-auto w-full md:w-2/3" />
            <BudgetChartImage
              className={cx(
                'absolute top-[25%] sm:top-[-14.5%] lg:top-0 xl:top-[9%] 2xl:top-[14%] left-[20%] sm:left-[3%] lg:left-[14%] xl:left-[20%] 2xl:left-[24%] scale-[2.21] sm:scale-[.55] lg:scale-[.72] xl:scale-[.9] 2xl:scale-[1.1] origin-bottom-right w-1/4 md:w-auto transition-all duration-300',
                {
                  'sm:!scale-90 lg:!scale-[1.2] xl:!scale-[1.4] 2xl:!scale-[1.6]':
                    scrollY > 300,
                }
              )}
            />
            <InvoiceChartImage
              className={cx(
                'absolute bottom-[-20%] sm:bottom-[-16.5%] lg:bottom-[-5%] xl:bottom-[2%] 2xl:bottom-[5%] left-[-10%] sm:left-[12.5%] lg:left-[19%] xl:left-[23%] 2xl:left-[24.5%] scale-90 sm:scale-[.55] lg:scale-[.75] xl:scale-[.95] 2xl:scale-[1.1] transition-all duration-300 origin-top-right',
                {
                  'sm:!scale-90 lg:!scale-[1.2] xl:!scale-[1.4] 2xl:!scale-[1.6]':
                    scrollY > 300,
                }
              )}
            />
            <NegotiationChartImage
              className={cx(
                'absolute -bottom-24 lg:-bottom-14 right-[10%] translate-x-1/2 sm:translate-x-full scale-75 lg:scale-125 sm:opacity-0 transition-all duration-500',
                {
                  '!opacity-100 sm:!translate-x-10 xl:!translate-x-0 md:scale-90 lg:scale-150':
                    scrollY > 300,
                }
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTool;
