import React, { useState } from 'react';
import SectionTtitle from '../common/sectionTtitle';
import firstslide from '../../assets/empoweringCritical/firstslide.png';
import secondslide from '../../assets/empoweringCritical/secondslide.png';
import thirdslide from '../../assets/empoweringCritical/thirdslide.png';
import fourthslide from '../../assets/empoweringCritical/fourthslide.png';
import downArrow1 from '../../assets/empoweringCritical/downArrow1.svg';
import Slider from 'react-slick';

const slides = [
  {
    key: 0,
    url: firstslide,
    description:
      'Our core vision is to make critical thinking the driving force for achieving national preparedness goals. Join us on this journey towards success and limitless possibilities.',
  },
  {
    key: 1,
    url: secondslide,
    description:
      'For the past 3 years, we have unraveled the most daunting challenges of companies delivering transformative solutions through our commitment to simplifying complexities. Experience the power of our vision today.',
  },
  {
    key: 2,
    url: thirdslide,
    description:
      'Embrace a future of limitless possibilities with our revolutionary approach to reshaping the landscape of preparedness. Join us and propel your business towards unrivaled success.',
  },
  {
    key: 3,
    url: fourthslide,
    description:
      'We are dedicated to simplifying complexities and delivering transformative solutions, setting us apart in our mission to achieve national preparedness goals. Experience the power of our vision and unlock your business true potential today.',
  },
];

const EmpoweringSlider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    beforeChange: (current, next) => setSlideIndex(next),
    centerMode: true,
  };

  return (
    <section id="critical">
      <div className="p-7 bg-white">
        <SectionTtitle
          title="Empowering Critical Thinking For National Resilience"
          description="Distinct critical necessary to achieve the National Preparedness Goal."
          className="pt-4"
        />
      </div>
      <div className="hidden md:block w-[85%] ml-[85px]">
        <Slider {...settings}>
          {slides.map((slide) => (
            <div key={slide.key} className="w-full">
              <div
                className={
                  slideIndex === slide.key
                    ? 'empowerCriticalslide empowerCriticalslide-active'
                    : 'empowerCriticalslide'
                }
              >
                <img
                  src={slide.url}
                  alt="slide"
                  className="w-[100%] object-contain"
                />
              </div>
              <div className="translate-y-[-20%] relative right-1/2 translate-x-0 w-[200%] m-auto text-[14px] lg:text-[18px] xl:text-[25px] text-[#000000] opacity-[0.6] text-center font-medium pt-12">
                <div
                  className={
                    slideIndex === slide.key
                      ? 'empowerCriticalslideblock'
                      : 'hidden'
                  }
                >
                  {slide.description}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="md:hidden flex flex-col gap-3 p-6">
        {slides.map((slide) => (
          <div key={slide.key} className="relative">
            <img src={slide.url} alt="slides" />
            <div className="absolute inset-0 bg-black/40" />
            <div className="group/click-btn absolute z-20 bottom-[4px] overflow-hidden">
              <div className="transition-all duration-300 translate-y-[80%] group-hover/click-btn:-translate-y-0">
                <img src={downArrow1} alt="down" className="mx-auto" />
                <div className="text-white bottom-0 opacity-0 group-hover/click-btn:opacity-100">
                  {slide.description}
                </div>
              </div>
              <div className="text-white text-center w-full group-hover/click-btn:opacity-0">
                {slide.description.split(' ').slice(0, 5).join(' ')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EmpoweringSlider;
