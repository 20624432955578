import React from 'react';
import facebookicon from '../../../src/assets/Icon/Facebook.png';
import instagramicon from '../../../src/assets/Icon/Instagram.png';
import twittericon from '../../../src/assets/Icon/Twitter.png';
import linkedinicon from '../../../src/assets/Icon/Linkedin.png';
import blueLogo from '../../assets/vmalogo.png';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="bg-[#DDDDDD]">
      <div className="shrink px-2 md:px-0 grid sm:grid-cols-2 gap-y-5 md:grid-cols-3 pt-6 pb-10 pl-[24px] sm:pb-[25px]">
        <div className="flex md:justify-center">
          <div>
            <p className="font-bold text-[20px] sm:text-[25px]">Our Services</p>
            <ul className="list-none flex flex-col gap-3 mt-2 font-normal text-[16px] sm:text-[18px]">
              <li>App Development</li>
              <li>Web Development</li>
              <li>Digital Marketing</li>
              <li>SAP ERP</li>
            </ul>
          </div>
        </div>
        <div className="sm:flex sm:justify-center">
          <div>
            <p className="font-bold text-[20px] sm:text-[25px]">
              About Company
            </p>
            <ul className="list-none flex flex-col gap-3 mt-2 font-normal  text-[16px] sm:text-[18px]">
              <Link to="/careers">Careers</Link>
              <Link to="/projects">Upcoming Projects</Link>
              <Link to="/about">About VMA Labs</Link>
            </ul>
          </div>
        </div>
        <div className="flex justify-start md:justify-center">
          <div>
            <p className="font-bold text-[20px] sm:text-[25px]">Useful Links</p>
            <ul className="list-none flex flex-col gap-3  mt-2 font-normal text-[16px] sm:text-[18px]">
              <li>
                <Link to="/terms">Terms Of Service</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="block sm:hidden">
        <div className="w-[80%] mx-auto border border-[#000000] opacity-[0.2]"></div>
      </div>
      <div className="bg-[#DDDDDD] sm:bg-[#CDCDCD]">
        <div className=" lg:pl-[60px] flex flex-col-reverse gap-4 md md:grid gap-y-8 md:grid-cols-2 lg:grid-cols-3 lg:px-[60px] py-[25px]">
          <div className="ml-[25px] flex justify-start items-center">
            <div className="flex flex-col gap-1">
              <img
                src={blueLogo}
                alt="logo"
                className="relative right-1 w-[70%]"
              />
              <div className="text-[15px] sm:text-[18px]">
                <span>&#169;copyrights</span>
                <span className="font-medium pl-1">VMA LABS</span>
              </div>
              <p className="text-[15px] sm:text-[18px]">All Rights Reserved</p>
            </div>
          </div>

          <div className="block sm:hidden p">
            <div className="w-[80%] m-auto border border-[#000000] opacity-[0.2]"></div>
          </div>

          <div className="hidden sm:block">
            <div className=" flex justify-start lg:justify-center pt-8">
              <div className="flex bg-white rounded-lg  rounded-r-2xl">
                <input
                  className="rounded-lg outline-none px-2 sm:px-4"
                  type="text"
                  placeholder="Connect With Us"
                />
                <button className="px-2 sm:px-5 py-1 rounded-lg bg-[#0091CF] text-white text-[18px] sm:text-[25px]">
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="md:mr-[57px] pl-[25px] sm:flex justify-start md:justify-start lg:justify-end items-center">
            <h2 className="block sm:hidden font-bold md:text-center text-[16px] sm:text-[25px]">
              Connect With Us
            </h2>
            <div className="flex gap-5 sm:gap-8 pt-3">
              <span>
                <Link
                  to="https://www.facebook.com/VMAtechlabs/"
                  target="_blank"
                >
                  <img
                    src={facebookicon}
                    className="w-5 sm:w-auto"
                    alt="facebookicon"
                  />
                </Link>
              </span>
              <span>
                <Link
                  to="https://www.instagram.com/vma_tech_labs/"
                  target="_blank"
                >
                  <img
                    src={instagramicon}
                    className="w-5 sm:w-auto"
                    alt="instagramicon"
                  />
                </Link>
              </span>
              <span>
                <Link
                  to="https://www.linkedin.com/company/vma-tech-labs/"
                  target="_blank"
                >
                  <img
                    src={linkedinicon}
                    className="w-5 sm:w-auto"
                    alt="linkedinicon"
                  />
                </Link>
              </span>
              <span>
                <Link to="https://twitter.com/VMA_Tech_Labs" target="_blank">
                  <img
                    src={twittericon}
                    className="w-5 sm:w-auto"
                    alt="twittericon"
                  />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
