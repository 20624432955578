import cx from 'classnames';
import React from 'react';

const SectionTtitle = ({
  children = '',
  title = '',
  description = '',
  className = '',
  isWhiteText = false,
}) => {
  return (
    <div className={cx({ '!text-white': isWhiteText })}>
      <div className={`flex justify-center items-center gap-2 ${className}`}>
        <div className="flex justify-center gap-1 md:gap-2 lg:gap-4 w-full lg:w-4/6">
          <div className="flex flex-row gap-1 md:gap-6 lg:gap-6 font-medium text-[14px] md:text-[20px] lg:text-[24px] xl:text-[30px]">
            <span className=" opacity-[0.1] flex align-middle">\</span>
            <span className=" opacity-[0.2]  flex align-middle">\</span>
            <span className=" opacity-[0.3]  flex align-middle">\</span>
            <span className=" opacity-[0.4]  flex align-middle">\</span>
          </div>
          <div className=" text text-[13px] md:text-[20px] lg:text-[24px] xl:text-[30px] px-1 md:px-4 font-medium text-center">
            <h2>{title || children}</h2>
          </div>
          <div className="flex flex-row gap-1 md:gap-6 lg:gap-6 text-[14px] font-medium  md:text-[20px] lg:text-[24px] xl:text-[30px]">
            <span className="opacity-[0.4]">/</span>
            <span className="opacity-[0.3]">/</span>
            <span className=" opacity-[0.2]">/</span>
            <span className="opacity-[0.1]">/</span>
          </div>
        </div>
      </div>
      <div className="indent-0 w-[90%] md:w-8/12 mx-auto mb-12 text-center text-[11px] sm:text-xl lg:text-2xl mt-4 opacity-70">
        {description}
      </div>
    </div>
  );
};

export default SectionTtitle;
