import React from 'react';
import Layout from '../components/layout';
import TalkSection from '../components/home/talkSection';
import Services from '../components/home/services';
import EmpoweringWork from '../components/section/empoweringWork';
import UpcomingProjects from '../components/home/upcomingProjects';
import ExploreHome from '../components/home/explore';
import AboutUs from '../components/home/aboutUs';
import HomeBackground from '../components/home/homeBackground';
import HomeCareerSection from '../components/home/homeCareer';
import MakingDifference from '../components/home/makingDifference';
import EmpoweringSlider from '../components/home/empoweringSlider';
import FlipPageSection from '../components/home/flipPageSection';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/" />
      </Helmet>
      <Layout className="relative">
        <FlipPageSection />
        <HomeBackground />
        <Services />
        <EmpoweringWork />
        <ExploreHome />
        <EmpoweringSlider />
        <AboutUs />
        <HomeCareerSection />
        <UpcomingProjects />
        <MakingDifference />
        <TalkSection />
      </Layout>
    </>
  );
};
export default HomePage;
