import React from 'react';
import OurExpertiese from '../components/services/ourExpertise';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const ServicePage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.vmatechlabs.com/services" />
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
      </Helmet>
      <Layout>
        <OurExpertiese />
      </Layout>
    </>
  );
};
export default ServicePage;
