import React from 'react';
import img1 from '../../../assets/map imges/files/flie.png';
import img2 from '../../../assets/map imges/files/new 1.png';
import img3 from '../../../assets/map imges/files/Slack_icon_2019 1.png';
import img4 from '../../../assets/map imges/files/folder 1.png';
import img5 from '../../../assets/map imges/files/microsoft 1.png';
import img6 from '../../../assets/map imges/files/Slack_icon_2019 1.png';
import img7 from '../../../assets/map imges/files/Stripe-Logo-2009 1.png';
import img8 from '../../../assets/map imges/files/logo-salesforce-png-454 1.png';
import vsyncLogo from '../../../assets/products/vsyncLogo.svg';
import img10 from '../../../assets/map imges/files/Vector 20.png';
import cx from 'classnames';

const solutions = () => {
  const solutions = [
    {
      Img: img1,
    },
    {
      Img: img2,
    },
    {
      Img: img3,
    },
    {
      Img: img4,
    },
    {
      Img: img5,
    },
    {
      Img: img6,
    },
    {
      Img: img7,
    },
    {
      Img: img8,
    },
    {
      Img: vsyncLogo,
    },
  ];

  return (
    <div className="bg-black w-full h-full ">
      <div className=" text-white bg-black mx-auto pt-5">
        <h2 className="text-center text-2xl sm:text-4xl">
          V-Sync, a Perfect Solution for All Business Sectors
        </h2>

        <p className="text-center py-5 text-sm sm:text-lg leading-relaxed w-full lg:w-[90%] mx-auto text-[#8F8F8F]">
          V-Sync is a visionary of how efficient corporate business management
          can be. As the next AI generative module for all types of business
          sectors like supply chain, manufacturing, health care, and other
          private & public sectors, V-Sync is the best innovative powerhouse.
          Our smart business management tool will reshape your business
          operation, and collaboration and drive significant growth.
        </p>
      </div>

      <div className=" mx-auto">
        <h2 className="ml-7 lg:ml-20 xl:ml-32 bg-gradient-to-r from-[#7C09C3] via-[#86D8F2] to-[#F69400] text-[26px] inline-block text-transparent bg-clip-text  ">
          Solutions
        </h2>

        <div className=" w-full md:w-[97%] lg:w-[78%] xl:w-[56%] mx-auto  py-3 sm:py-10 overflow-hidden">
          <div className="grid md:grid-cols-3 gap-x-[30px] gap-y-[70px]">
            {solutions.map(({ Img }, index) => (
              <div key={index} className="relative">
                <div
                  className={cx(
                    'h-[130px] w-[130px] rounded-full  mx-auto bg-[#121212]',

                    { 'bg-black z-70 ': index === solutions.length - 1 }
                  )}
                >
                  <div className="flex justify-center items-center h-full">
                    <img
                      src={Img}
                      alt="card-images"
                      className={cx('w-[50px] object-contain', {
                        'w-full -mr-4': index === solutions.length - 1,
                      })}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className=" hidden sm:block ml-24 lg:ml-28 -mt-[500px]">
            <img
              src={img10}
              alt="card-images"
              className="w-[570px] h-[470px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default solutions;
