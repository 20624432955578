import React from 'react';
import efficiency from '../../assets/digitalSign/efficiency.png';
import seamless from '../../assets/digitalSign/seamless.png';
import universal from '../../assets/digitalSign/universal.png';
import enhance from '../../assets/digitalSign/enhance.png';
import SectionTtitle from '../common/sectionTtitle';

const Offers = () => {
  const data = [
    {
      img: seamless,
      heading: 'Seamless Integration',
      details:
        'Easily integrates with multiple ERP systems, ensuring a cohesive workflow without disrupting your existing processes.',
    },
    {
      img: universal,
      heading: 'Universal Compatibility',
      details:
        'Works with popular ERP software, allowing your organization to maintain its preferred platforms.',
    },
    {
      img: efficiency,
      heading: 'Efficiency',
      details:
        'Speeds up document signing processes, reducing manual effort and associated errors.',
    },
    {
      img: enhance,
      heading: 'Enhanced Security',
      details:
        'Provides advanced encryption and authentication methods, ensuring the integrity and legality of digital signatures.',
    },
  ];
  return (
    <div id="offers" className="w-[98%] md:w-[90%] mx-auto pt-7">
      <SectionTtitle
        title="What we offer"
        description=" VMA LABS is an innovative management and technology consulting
        firm. We provide our Clients a comprehensive suite of systems
        integration"
      />
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-[2px]">
        {data.map(({ img, heading, details }, index) => (
          <div key={index} className="flex flex-col min">
            <img src={img} alt="images" />
            <div className="bg-[#222222] h-full text-white mt-[1px]">
              <div className="w-[90%] mx-auto">
                <p className="mt-4 mb-10 text-[18px] md:text-[20px] whitespace-nowrap">
                  {heading}
                </p>
                <p className="mb-6 text-[14px] md:text-[18px]">{details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offers;
