import React from 'react';
import userfriendly from '../../assets/digitalSign/userFriendly.png';
import compliance from '../../assets/digitalSign/compliance.png';
import costsaving from '../../assets/digitalSign/costsaving.png';
import digitalSign from '../../assets/digitalSign/digitalSign.png';
import cx from 'classnames';

const Details = () => {
  const data = [
    {
      title: 'User-Friendly',
      text: ' Offers an intuitive interface for both signatories and administrators, making it easy to implement and use.',
      image: userfriendly,
    },
    {
      title: 'Compliance',
      text: ' Adheres to legal and regulatory requirements for digital signatures, bolstering your document legal standing.',
      image: compliance,
    },
    {
      title: 'Cost Savings',
      text: ' Reduces the need for paper, printing, and physical signatures, resulting in cost savings and environmental benefits.',
      image: costsaving,
    },
  ];
  return (
    <div>
      {data.map(({ title, text, image }, index) => (
        <div
          key={index}
          className={cx(
            'md:flex w-[98%] md:w-[90%] mx-auto mt-10 bg-[#222222]',
            {
              'flex-row-reverse': index % 2 === 0,
            }
          )}
        >
          <div className="flex flex-col justify-end md:w-[45%] bg-[#222222] p-3 text-white">
            <div className="text-[18px] md:text-[25px]">{title}</div>
            <div className="text-[14] md:text-[18px] pt-2">{text}</div>
          </div>
          <div className="md:w-[55%]">
            <img src={image} alt="digitalImage" />
          </div>
        </div>
      ))}
      <div>
        <div className="md:flex items-center w-[70%] mx-auto my-4">
          <div className="md:w-[60%] mx-auto text-[20px]">
            Incorporating our Digital Signature Integration Solution into your
            ERP ecosystem enhances efficiency, security, and compliance while
            reducing operational costs.
          </div>
          <div>
            <img src={digitalSign} alt="coporateImage" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
