import React from 'react';
import about1 from '../../assets/aboutUs/about1.png';
import about2 from '../../assets/aboutUs/about2.png';
import about3 from '../../assets/aboutUs/about3.png';
import about4 from '../../assets/aboutUs/about4.png';
import knowMoreIcon from '../../assets/aboutUs/knowMoreIcon.png';
import SectionTtitle from '../common/sectionTtitle';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <section id="about" className="bg-[#1AD6FF] p-5">
      <SectionTtitle
        title="About Us"
        description="At VMA labs, our collective vision resolve around unlocking the genuine power and usage of technology.With our expertise,we are committed to converting ideas into groundbreaking solutions."
      />
      <div className="grid grid-cols-1 md:grid-cols-12 md:gap-4 border-0 md:border-white md:border-r-[5px] md:border-b-[5px] pb-8 mx-auto w-[90%] xl:w-[70%]">
        <div className="col-span-9 flex flex-col md:grid grid-cols-7 gap-4 sm:gap-1 mb-1">
          <div
            className="col-span-4 w-full relative bg-center bg-cover group group-hover:scale-110 transition-all duration-300 overflow-hidden"
            style={{
              backgroundImage: `url(${about1} )`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="flex flex-col justify-center items-center absolute bottom-0 left-[4px] lg:translate-y-[60%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <h1 className="text-center font-medium text-[15px] lg:text-[20px]  text-white">
                Passionate Innovators(What we do)
              </h1>
              <p className="hidden lg:block text-[16px] text-white text-center font-thin opacity-0 group-hover:opacity-80 transition-all duration-300 ">
                Driven by a relentless drive for innovation,we provide solutions
                that tackle complex challenges.Our team continuously seeks out
                new ideas,adopts cutting-edge technologies, and delivers
                transformative outcomes for our clients through novel
                approaches.
              </p>
            </div>
            <img src={about1} alt="one" className="w-full opacity-0" />
          </div>
          <div
            className="col-span-3 w-full  relative bg-center bg-cover group group-hover:scale-110 transition-all duration-300 overflow-hidden"
            style={{
              backgroundImage: `url(${about2} )`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="flex flex-col justify-center items-center absolute bottom-0 left-[4px] lg:translate-y-[60%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear ">
              <h1 className="text-center font-medium text-[15px] lg:text-[20px] text-white">
                Skilled Professionals(team)
              </h1>
              <p className="hidden lg:block text-[16px] text-white text-center font-thin opacity-0 group-hover:opacity-80 transition-all duration-300 ">
                We are a team of highly skilled professionals who are passionate
                about technology.With years of experience and expertise in
                various domains,we bring diverse skills and perspectives to the
                table.
              </p>
            </div>
            <img src={about2} alt="two" className="w-full opacity-0" />
          </div>
          <div
            className="col-span-5 w-full  relative bg-center bg-cover group group-hover:scale-110 transition-all duration-300 overflow-hidden"
            style={{
              backgroundImage: `url(${about3} )`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="flex flex-col justify-center items-center absolute bottom-0 left-[4px] lg:translate-y-[60%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear ">
              <h1 className="text-center font-medium text-[15px] lg:text-[20px]  text-white">
                Driven by Excellence(what to expect)
              </h1>
              <p className="hidden lg:block text-[16px] text-white text-center font-thin opacity-0 group-hover:opacity-80 transition-all duration-300 ">
                Our commitment to detail,quality and customer satisfaction
                powers our work.With relentless pursuit,we consistently deliver
                solutions of the highest standards,providing exceptional value
                to our clients.
              </p>
            </div>
            <img src={about3} alt="three" className="w-full opacity-0" />
          </div>
          <div
            className="col-span-2 w-full relative bg-center bg-cover group group-hover:scale-110 transition-all duration-300 overflow-hidden"
            style={{
              backgroundImage: `url(${about4} )`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="flex flex-col justify-center items-center absolute bottom-0 left-[4px] lg:translate-y-[60%] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear ">
              <h1 className="text-center font-medium text-[15px] lg:text-[20px] text-white">
                Harnessing Digital Potential(Vision)
              </h1>
              <p className="hidden lg:block text-[16px] text-white text-center font-thin opacity-0 group-hover:opacity-80 transition-all duration-300 ">
                Paving the way for a boundless future where technology
                seamlessly enhances our lives.Making technology simpler,more
                efficient & enjoyable.
              </p>
            </div>
            <img src={about4} alt="four" className="w-full opacity-0" />
          </div>
        </div>
        <div className="md:col-span-2 relative w-full bg-white text-black ">
          <div className="sm:absolute flex flex-col items-center sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 md:w-[250%] md:-rotate-[90deg] py-5 sm:py-0">
            <img
              src={knowMoreIcon}
              alt="icons"
              className="rotate-90 cursor-pointer"
              onClick={() => navigate('about')}
            />
            <div className="text-xl xl:text-3xl text-center">
              Know More About Us
            </div>
          </div>
        </div>
        <div className="border-0 md:border-white md:border-t-[5px]" />
      </div>
    </section>
  );
};

export default AboutUs;
