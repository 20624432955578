import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import IntroHome from './introHome';
import businesswomen from '../../assets/images/businesswomen.png';
import colleagues from '../../assets/images/colleagues.png';
import young from '../../assets/images/young.png';

const HomeBackground = () => {
  const [count, setCount] = useState(1);
  const changeImage = useCallback(() => {
    setTimeout(() => {
      if (count === 3) setCount(1);
      else setCount((prevCount) => prevCount + 1);
    }, 3000);
  }, [count]);
  useEffect(() => {
    changeImage();
  }, [changeImage]);

  return (
    <section id="home" className="w-full mx-auto lg:h-[110vh] overflow-hidden">
      <div className="">
        <div className="relative">
          <div className="hidden sm:block">
            <img
              src={colleagues}
              alt="colleaguesImg"
              className={cx(
                'w-full mx-auto absolute top-0 transition-all duration-500',
                {
                  'opacity-100': count === 2,
                }
              )}
            />
            <div className="absolute top-0 w-full h-full bg-gradient-to-r from-black to-transparent" />
          </div>
          {/* <div className="absolute top-0 h-full w-full bg-black/40" /> */}
          <img
            src={colleagues}
            alt="colleaguesImg"
            className="opacity-0 hidden md:block"
          />
          <IntroHome count={count} />
        </div>
      </div>
    </section>
  );
};

export default HomeBackground;
