import React from 'react';
import notFound from '../assets/notFound.png';
import Atwork from '../assets/Atwork.png';
import movingNotFoundS from '../assets/movingNotFoundS.svg';
import movingNotFoundB from '../assets/movingNotFoundB.svg';
import arrowIcon from '../assets/Icon/arrowIcons.png';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const UnderMaintenance = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link
          rel="canonical"
          href="https://www.vmatechlabs.com/under-maintainance"
        />
      </Helmet>
      <div
        className="bg-center bg-cover relative"
        style={{
          backgroundImage: `url(${notFound})`,
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100vh',
        }}
      >
        <div className="absolute inset-0 bg-black/90" />
        <div className="absolute inset-0 z-20">
          <div className="flex justify-start lg:p-20 md:p-10 p-5 ">
            <img
              src={arrowIcon}
              onClick={() => navigate(-1)}
              className="cursor-pointer "
              alt="arrow"
            />
          </div>
          <div className="flex gap-3 absolute z-20 left-[30%] md:left-[40%] top-[32%] ">
            <h1 className="font-semibold text-white text-2xl md:text-[80px]">
              PAGE
            </h1>
            <div>
              <div className="relative bottom-[68px] left-[43px] md:left-[83px] bg-[#FFFF00] rounded-full">
                <img
                  src={movingNotFoundS}
                  alt="small"
                  className="spin-anticlockwise"
                />
              </div>
              <div className="relative bottom-[85px] md:left-[43px] bg-[#FFFF00] rounded-full">
                <img
                  src={movingNotFoundB}
                  alt="big"
                  className="animate-[spin_3s_linear_infinite]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-center text-white absolute z-20 bottom-[49%]  text-[26px] md:text-4xl">
          UNDER <span className="text-[#FFFF00]">MAINTENANCE</span>
        </div>
        <div className="w-full text-center text-white absolute z-40  top-[49%] text-sm md:text-xl mt-8">
          We are currently undergoing
          <span className="text-[#FFFF00]">maintenance</span> to improve our
          services. Please bear with us and check back later.
        </div>
        <div className="absolute z-20 top-[66%] lg:top-[62%] left-[20%] w-[60%] h-[7%] bottom-[10px] overflow-hidden">
          <div className="strips w-[300%] h-full" />
        </div>

        <div className="fixed z-20 bottom-0 md:bottom-[25px] right-0 ">
          <div className="bg-black rounded-r-lg rounded-tl-lg w-[40%] lg:w-[66%] relative right-0 z-20 rotate-[-44deg] bottom-[20px] left-[21px]">
            <img
              src={Atwork}
              alt="work"
              className="w-[84px] lg:w-[128px] h-[68px] lg:h-[108px] rotate-[45deg] scale-[1.4]"
            />
          </div>
          <div className="relative w-[100%] bg-[#FFFF00] text-black rounded-lg p-3 right-[11px] bottom-[11px]">
            Our Team is At Work
          </div>
        </div>
      </div>
    </>
  );
};

export default UnderMaintenance;
