import React from 'react';

const KnowPotential = () => {
  return (
    <div className="bg-[#F38434] relative h-screen">
      <div className="absolute top-0 left-0 border-t-[5px] border-white"></div>
      <div className="grid grid-cols-12">
        <div className="col-span-4"></div>
        <div className="col-span-4"></div>
      </div>
    </div>
  );
};

export default KnowPotential;
