import React from 'react';
import Layout from '../components/layout';
import Integration from '../components/eInvoice/integration';
import InvoiceFeature from '../components/eInvoice/invoiceFeature';
import BenefitInvoice from '../components/eInvoice/benefitInvoice';
import Empower from '../components/eInvoice/empower';
import { Helmet } from 'react-helmet';
import FormContactpage from '../components/contact/formContactpage';
import contact from '../assets/images/contact.svg';
import { Link } from 'react-scroll';

const EInvoice = () => {
  return (
    <>
      <Helmet>
        <title>
          Best E Invoicing Software Online for Business | Generate GST
          e-Invoices with Tally, SAP & any other ERP in Minutes
        </title>
        <meta
          name="description"
          content="Best e-Invoicing Online Software for Business and Generate GST e-Invoices with Tally, SAP & any other ERP in Minutes with fast, dependable, and affordable solutions for all your e-Invoicing needs @VMA Labs."
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/e-invoice" />
      </Helmet>
      <Layout>
        <div className="relative">
          <Link to="contactus" smooth={true} duration={500}>
            <img
              src={contact}
              alt="contact"
              className="fixed right-[3%] top-[20%] z-[110] cursor-pointer"
            />
          </Link>
        </div>
        <Integration />
        <InvoiceFeature />
        <BenefitInvoice />
        <Empower />
        <FormContactpage />
      </Layout>
    </>
  );
};

export default EInvoice;
