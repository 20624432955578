import React from 'react';
import checkMark from '../../../assets/checkMark.png';
import gif from '../../../assets/vsync.gif';

const Innovation = () => {
  const innovationData = [
    {
      title: 'Tailored Solutions',
      brief:
        ' Each business is unique. V-Sync offers personalized solutions for all business needs.',
    },
    {
      title: '24/7 Tech Support',
      brief:
        'Our dedicated tech support team is available 24/7 ensuring seamless V-Sync experience.',
    },
    {
      title: ' Easy to Manage',
      brief: 'No need for any tech or software knowledge to manage V-Sync.',
    },
  ];
  return (
    <div className="bg-black pt-4">
      <div className="text-white text-center md:text-[40px]">
        <p>An Innovative Solution for</p>
        <p> Corporate Business Management</p>
      </div>
      <div className="text-center text-[20px] text-[#8F8F8F] w-[68%] m-auto font-thin">
        V-Sync is a smart business management tool designed to empower
        businesses by uncovering potential opportunities for more effective
        business operations. Our AI-powered smart corporate management tool is a
        perfect business solution that seamlessly integrates with your existing
        ERP System, ensuring you manage your business efficiently.
      </div>
      <div className="pb-4 w-[80%] m-auto">
        <h1 className="text-transparent text-[26px] font-[400] capitalize bg-clip-text bg-gradient-to-r from-[#7C09C3] via-[#86D8F2] to-[#F69400] inline-block pt-12 pb-6 ">
          management
        </h1>
        <div className="sm:flex grid pl-[5%]">
          <div className="md:w-1/2 ">
            <div className="gap-10">
              {Array.isArray(innovationData) &&
                innovationData.map(({ title, brief }, index) => (
                  <div key={index} className="pt-4">
                    <p className="bg-[#1F1F1F]  items-center text-white rounded-2xl text-[18px] md:text-[24px] font-[400] inline-flex px-4 py-2 gap-3">
                      <img src={checkMark} className="w-[15%]" />
                      {title}
                    </p>
                    <p className=" md:text-start md:w-[60%] md:mr-auto text-[#AAAAAA] text-[20px] font-thin pt-2">
                      {brief}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="pt-16 md:w-[50%] ">
            <img src={gif} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
