import React from 'react';
import empowerImg from '../../assets/e-invoice/envoice.jpg';

const Empower = () => {
  return (
    <>
      <div className="relative min-h-[50vh] ">
        <img
          src={empowerImg}
          alt="background"
          className="w-full h-[70vh] sm:h-[95vh] object-cover "
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className=" p-6 text-white text-center w-[80%] m-auto">
            <p className=" md:text-[25px] text-[15px]  py-2">
              Our E-Invoice Integration Solution for Multiple ERP Systems across
              multiple countries empowers your organization to efficiently
              manage e-invoicing across diverse ERP platforms, promoting
              accuracy, compliance and cost savings.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Empower;
