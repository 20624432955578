import React from 'react';
import Experience from './experience';
import Empowering from './empowering';
import Critical from './criticalThinking';
import AboutUs from './aboutUs';
import MakingDiff from './makingDiff';
import Projects from './projects';
import LetsTalk from './letsTalk';
import HomeIntroForm from './homeIntroForm';
import Explore from './explore';
import MetaInfo from './metaInfo';
import { Helmet } from 'react-helmet';

const AllSection = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/admin" />
      </Helmet>
      <div>
        <MetaInfo />
        <HomeIntroForm />
        <Experience />
        <Empowering />
        <Explore />
        <Critical />
        <AboutUs />
        <MakingDiff />
        <Projects />
        <LetsTalk />
      </div>
    </>
  );
};

export default AllSection;
