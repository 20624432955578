import React, { useEffect, useState } from 'react';
import withSectionIds from '../containers/withSectionIds';
import cx from 'classnames';
import { Link } from 'react-scroll';

const scrollConfig = {
  duration: 500, // Duration of the scroll animation in milliseconds
  offset: -50, // Offset from the top of the target element (useful for fixed headers)
  smooth: true, // Enable smooth scrolling
};

const FlipPageSection = ({
  sections,
  isActiveSection,
  isVisitedSection,
  currentSection,
}) => {
  const [showOnTop, setShowOnTop] = useState(currentSection);
  const [howerLink, setHowerLink] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowOnTop(sections[currentSection].id);
    }, 500);
  }, [currentSection]);

  return (
    <>
      <div
        className={cx(
          'hidden xl:block fixed top-[35%] right-0 w-[5%] transition-all duration-[1s] z-[100]',
          {
            '-translate-y-36': howerLink,
          }
        )}
      >
        {sections.map(({ id, icon }) => (
          <div
            key={id}
            className={cx(
              'top-0 section-cards transition-transform duration-[2s] origin-[4%_4%] absolute z-[100]',
              {
                'flip-page': isVisitedSection(id) && !isActiveSection(id),
                'z-[110]': isActiveSection(id),
                'z-[120]': showOnTop === id,
              }
            )}
            onMouseEnter={() => setHowerLink(true)}
            onMouseLeave={() => setHowerLink(false)}
          >
            <div>
              <img src={icon} alt="quotes" />
            </div>
          </div>
        ))}
        <div
          className={cx(
            'scale-y-0 translate-y-[103px] transition-transform duration-[1s] origin-top z-50',
            { 'scale-y-100 -translate-y-10': howerLink }
          )}
          onMouseEnter={() => setHowerLink(true)}
          onMouseLeave={() => setHowerLink(false)}
        >
          {sections.map(({ id, title }) => (
            <Link
              key={id}
              to={id}
              spy={true}
              smooth={scrollConfig.smooth}
              offset={scrollConfig.offset}
              duration={scrollConfig.duration}
              className={cx('group/menu text-[#DDD] text-center text-[12px]', {
                hidden: isActiveSection(id),
              })}
            >
              <div className="bg-[#3E3E3E] border-b border-black w-[70px] h-[56px]">
                <div className="flex justify-between items-center text-black pl-2">
                  <li></li>
                  <li></li>
                </div>
                <div
                  className={cx(
                    'flex justify-center items-center underline line-clamp-2 whitespace-pre-wrap group-hover/menu:text-[#BCD7FF] cursor-pointer',
                    { 'text-[#BCD7FF]': isActiveSection(id) }
                  )}
                >
                  {title}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default withSectionIds(FlipPageSection);
