import React, { useState } from 'react';
import ImageUpload from './imageUpload';

const MetaInfo = () => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className=" justify-center border-3 shadow-lg p-10 md:w-[60%] sm:w-[20%] mx-auto my-10  bg-white">
        <div className="text-4xl font-bold text-center mb-12">Meta Info</div>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center flex-wrap lg:gap-12 md:gap-4 ">
            <div className="mb-8 flex flex-wrap gap-2">
              <div className="">
                <label htmlFor="name" className="text-xl font-medium mb-1">
                  Title
                </label>
              </div>
              <div className="w-[100%]">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-[95%] px-3 py-2 border rounded"
                  required
                />
              </div>
            </div>
            <div>
              <ImageUpload />
            </div>
          </div>

          <div className="mb-8 mt-6 flex flex-wrap md:gap-8 gap-6">
            <div className="">
              <label htmlFor="name" className=" text-xl font-medium mb-1">
                Description
              </label>
            </div>
            <div className="md:w-[90%] sm:w-[10%] border-2">
              <textarea
                id="teaxtarea"
                name="textarea"
                placeholder=""
                rows="8"
                className="w-full"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button className="bg-gray-500  text-white font-bold py-2 px-4 rounded">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MetaInfo;
