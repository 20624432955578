import React from 'react';
import Layout from '../components/layout';

import CareerBackground from '../components/careers/careerBackground';
import WhyVmaSection from '../components/careers/whyVma';
import Opportunities from '../components/careers/opportunities';
import { Helmet } from 'react-helmet';

const CareersPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/careers" />
      </Helmet>
      <Layout>
        <CareerBackground />
        <Opportunities />
        <WhyVmaSection />
      </Layout>
    </>
  );
};

export default CareersPage;
