import React from 'react';
import careerImg from '../../assets/careerImages/careersteps.png';
import './flip.css';

const FlipAnimation = () => {
  return (
    <div className="flip-card-container float-left p-[2.6%] ">
      <div className="flip-card h-[250px] sm:h-[450px]">
        <div className="flip-card-inner">
          <div className="flip-card-front1 rounded-l-2xl" />
          <div className="flip-card-back1 rounded-l-2xl" />
        </div>
      </div>
      <div className="flip-card h-[250px] sm:h-[450px]">
        <div className="flip-card-inner">
          <div className="flip-card-front2" />
          <div className="flip-card-back2" />
        </div>
      </div>
      <div className="flip-card h-[250px] sm:h-[450px]">
        <div className="flip-card-inner">
          <div className="flip-card-front3" />
          <div className="flip-card-back3" />
        </div>
      </div>
      <div className="flip-card h-[250px] sm:h-[450px]">
        <div className="flip-card-inner">
          <div className="flip-card-front4" />
          <div className="flip-card-back4" />
        </div>
      </div>
      <div className="flip-card h-[250px] sm:h-[450px]">
        <div className="flip-card-inner">
          <div className="flip-card-front5 rounded-r-2xl" />
          <div className="flip-card-back5 rounded-r-2xl" />
        </div>
      </div>
      <img
        alt="flip"
        className="absolute top-[9px] md:top-[19px] lg:top-[24px] xl:top-[16px] pl-[18%] md:pl-[11%] "
        src={careerImg}
      />
      <p className="absolute -rotate-90 sm:text-3xl pb-[90%] xl:pb-[55%] pt-[10%] sm:pt-[15%] pr-[10%] xl:pr-0 text-white opacity-60">
        VMA LABS
      </p>
    </div>
  );
};

export default FlipAnimation;
