import React from 'react';
//image assets
import efficiencyIcon from '../../../src/assets/e-invoice/efficiencyIcon.png';
import accuracyIcon from '../../../src/assets/e-invoice/accuracyIcon.png';
import costSavingIcon from '../../../src/assets/e-invoice/costSavingIcon.png';
import centControlIcon from '../../../src/assets/e-invoice/centControlIcon.png';
import scalabilityIcon from '../../../src/assets/e-invoice/scalabilityIcon.png';
import complianceIcon from '../../../src/assets/e-invoice/complianceIcon.png';
// Defined Item component
const Card = ({ cardTitle = '', description, title, image }) => {
  return (
    <>
      <div className="block">
        <div className="flex flex-row justify-center sm:justify-start">
          <img className="w-[96px]" src={image} alt="ImageAltText" />
        </div>
        <div>
          <p
            className={`font-semibold text-black text-[20px] md:text-[24px] text-center sm:text-left my-4`}
          >
            {title}
          </p>
          <p
            className={`font-normal text-[16px] md:text-[20px] text-black text-center sm:text-left mb-6`}
          >
            {cardTitle || description.split(' ').slice(0, 20).join(' ')}
          </p>
        </div>
      </div>
    </>
  );
};
// Defined Empower component
const Benefits = () => {
  // Defined data for cards
  const CardData = [
    {
      id: 1,
      title: 'Efficiency',
      description:
        'Streamline invoicing processes by eliminating the need to manually reconcile data across multiple ERPs.',
      image: efficiencyIcon,
    },
    {
      id: 2,
      title: 'Accuracy',
      description:
        'Minimize errors associated with duplicate data entry, ensuring accurate and consistent invoicing.',
      image: accuracyIcon,
    },
    {
      id: 3,
      title: 'Cost Saving',
      description: 'Reduce costs through more efficient invoicing processes.',
      image: costSavingIcon,
    },
    {
      id: 4,
      title: 'Centralized Control',
      description:
        'Maintain centralized control over your invoicing operations, enhancing visibility and accountability.',
      image: centControlIcon,
    },
    {
      id: 5,
      title: 'Scalability',
      description:
        'Easily scale the solution as your business grows or integrates new ERP systems.',
      image: scalabilityIcon,
    },
    {
      id: 6,
      title: 'Compliance',
      description:
        'Ensure compliance with local and international invoicing regulations across all ERPs, reducing the risk of fines or penalties.',
      image: complianceIcon,
    },
  ];
  return (
    <div>
      <div className="min-h-[74vh] grid grid-cols-1 md:grid-row-2 gap-20 md:gap-8 w-[90%] my-1  m-auto p-5">
        <p className="text-[30px] md:text-[40px] font-medium my-[2px] sm:my-4 text-center">
          Benefits of E-Invoice
        </p>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {CardData.map((data) => (
            <div key={data.id}>
              <div className="text-center">
                <Card
                  key={data.id}
                  id={data.id}
                  image={data.image}
                  description={data.description}
                  title={data.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// Defined EInvoice component to render Empower component
const EInvoice = () => {
  return (
    <div>
      {/* Rendered Empower component */}
      <Benefits />
    </div>
  );
};

// Exported EInvoice component
export default EInvoice;
