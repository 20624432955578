import React from 'react';
import Carousel from './carsolue.js';
import SectionTtitle from '../common/sectionTtitle';

const ModalOfApproach = () => {
  return (
    <div>
      <div>
        <SectionTtitle
          className="pt-12"
          title="OUR MODAL OF APPROACH"
          description=" VMA LABS is an innovative management and technology consulting
          firm. We provide our Clients a comprehensive suite of systems
          integration"
        />
      </div>
      <div className="py-10 w-[70%]  m-auto">
        <Carousel />
      </div>
    </div>
  );
};

export default ModalOfApproach;
