import Slider from 'react-slick';
import nextArrow from '../../assets/empowerWorks/nextArrow.png';
import previousArrow from '../../assets/empowerWorks/previousArrow.png';
import SectionTtitle from '../common/sectionTtitle';
function SampleNextArrow({ onClick }) {
  return (
    <div
      className="arrow arrow-right w-5 sm:w-[50px] absolute top-[20%] sm:top-0 right-0 z-10 cursor-pointer"
      onClick={onClick}
    >
      <div className="">
        <img
          className="text-[#FFFFFF] opacity-[0.5]"
          src={nextArrow}
          alt="nextArrow"
        />
      </div>
    </div>
  );
}
function SamplePrevArrow({ onClick }) {
  return (
    <div
      className="arrow arrow-left w-5 sm:w-[50px] absolute top-[20%] sm:top-0 left-0 z-10 cursor-pointer"
      onClick={onClick}
    >
      <div className="">
        <img
          className="text-[#FFFFFF] opacity-[0.5]"
          src={previousArrow}
          alt="previousArrow"
        />
      </div>
    </div>
  );
}

const Array = [
  {
    description:
      'Trusted by 50+ Companies, We Simplify Challenges with Innovative Solutions',
  },
  {
    description:
      'join us to experience our transformation impact ,making complex problems easy and propelling your business forward.',
  },
];
const MakingDifference = () => {
  const settings = {
    line: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section id="difference" className="bg-black">
      <div className="py-20 bg-black md:w-[96%] m-auto text-white">
        <SectionTtitle isWhiteText title="How We Are Making A Difference" />
        <div className="w-full sm:w-4/6 mx-auto sm:mt-8">
          <Slider {...settings}>
            {Array.map((des, index) => (
              <div key={index} className="relative object-contain">
                <div
                  className="w-[70%] m-auto flex justify-center overflow-hidden text-center"
                  key={index}
                >
                  <h2 className="text-[#FFFFFF] opacity-[0.7] text-[11px] md:text-[18px] lg:text-[22px] font-medium text-center">
                    {des.description}
                  </h2>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default MakingDifference;
