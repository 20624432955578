import React from 'react';
import bgimg from '../../../assets/Rectangle6642.png';
import Button from '../../common/button';
import { Link } from 'react-scroll';

const AiManagement = () => {
  return (
    <>
      <div className=" bg-black  w-full h-full py-7 ">
        <div className="relative w-[95%] sm:w-[90%] mx-auto rounded-2xl sm:rounded-4xl overflow-hidden ">
          <div className="absolute inset-0 bg-[#353535]/80" />

          <img
            src={bgimg}
            alt="background"
            className="w-full h-[400px] sm:h-[auto] object-cover opacity-80"
          />

          <div className="absolute grid sm:grid-cols-2 left-0 top-0 mx-5 xl:mx-10  my-7 xl:my-20">
            <div>
              <h2 className="text-white text-[21px] sm:text-[24px] lg:text-[33px] xl:text-[40px] my-2 sm:my-0">
                Join the Future of AI-powered Business Management with V-Sync
              </h2>

              <p className="text-[15px] md:text-[14px] lg:text-[20px] text-[#E3E3E3] py-2 sm:py-0 font-thin">
                Ready to experience an efficient way of business management?
                Sign up for a free demo and unlock the full potential of our
                AI-powered corporate management tool and manage your business
                operation like never before.
              </p>
            </div>

            <div>
              <div className="absolute right-4 -bottom-20 md:bottom-0  xl:-bottom-10">
                <Button className="bg-white text-[16px] md:text-[13px] lg:text-[16px] font-semibold p-3 hover:shadow-[-6px_6px_0px_0px] hover:shadow-[#D8D9DA] hover:translate-x-1 hover:translate-y-1">
                  <Link to="contactus" smooth={true} duration={500}>
                    Request Free Demo
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AiManagement;
