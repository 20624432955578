import React from 'react';
import vmaLogo from '../../assets/whitelogovma.png';
import Subtract from '../../assets/Subtract.png';
import SectionTtitle from '../common/sectionTtitle';

const UpcomingProjects = () => {
  return (
    <section id="projects" className="bg-white w-full relative p-5">
      <SectionTtitle
        className="pt-10"
        title="Our Upcoming Project"
        description="With over 15+ ongoing projects,our company is constantly pushing the
        boundaries of innovation.We are commiting to ensuring 100% satisfaction,
        delivering exceptional results that exceed expectations."
      />
      <div
        className="relative w-[80%] sm:w-[90%] mx-auto min-h-[350px] sm:min-h-auto bg-no-repeat bg-center bg-cover sm:!bg-none shadow-lg"
        style={{ backgroundImage: `url(${Subtract})` }}
      >
        <div className="flex justify-center items-center absolute top-[-25px] right-[-25px] sm:right-[-51px] sm:top-[-50px] lg:top-[-80px] lg:right-[-60px] z-10  sm:w-[180px] lg:w-[276px] sm:h-[180px] lg:h-[276px] bg-white rounded-full p-2 sm:p-0">
          <div className="relative sm:top-[17px] sm:right-[21px] rounded-full border-2 border-[#000000] border-dashed w-[60px] sm:w-[100px] lg:w-[164px] h-[60px] sm:h-[100px] lg:h-[164px]">
            <div className="border-[5px] sm:bottom-[10px] lg:border-[15px] rounded-full border-[#000000] animate-[spin_3s_ease-in-out_infinite] w-full h-full  border-t-transparent border-r-transparent border-l-transparent"></div>
          </div>
        </div>
        <div className="absolute inset-0 bg-gradient-to-br from-black/90" />
        <img
          src={Subtract}
          alt="background"
          className="hidden sm:block w-full object-cover"
          style={{ filter: 'drop-shadow(#00000066)' }}
        />
        <div className="flex flex-col justify-center items-center gap-4 md:gap-10 absolute inset-0">
          <img src={vmaLogo} alt="logo" className="relative w-2/3 sm:w-2/5 object-contain z-10" />
          <div className="grid grid-cols-3 border-[3px] border-white w-[60%] h-[10px] sm:h-[24px]">
            <div className="bg-white col-span-1"></div>
          </div>
          <div className="text-white md:text-5xl">
            <b>LOADING...</b>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingProjects;
