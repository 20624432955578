import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import BusinessTool from '../../components/products/vsync/businessTool';
import Features from '../../components/products/vsync/features';
import Innovation from '../../components/products/vsync/innovation';
import Utilization from '../../components/products/vsync/utility';
import Solutions from '../../components/products/vsync/solutions';
import AiManagement from '../../components/products/vsync/aiManagement';
import FormContactpage from '../../components/contact/formContactpage';
import contact from '../../assets/images/contact.svg';
import { Link } from 'react-scroll';

const Vsync = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link
          rel="canonical"
          href="https://www.vmatechlabs.com/products/vsync"
        />
      </Helmet>
      <Layout>
        <div className="">
          <div className="relative">
            <Link to="contactus" smooth={true} duration={500}>
              <img
                src={contact}
                alt="contact"
                className="fixed right-[3%] top-[20%] md:top-[15%] z-[110] cursor-pointer"
              />
            </Link>
          </div>
          <BusinessTool />
          <Innovation />
          <Features />
          <Utilization />
          <Solutions />
          <AiManagement />
          <div className="bg-black">
            <FormContactpage dark />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Vsync;
