import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation, useNavigate, Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import vmaLogo from '../../assets/whitelogovma.png';
import blueLogo from '../../assets/vmalogo.png';
import navbarIcon from '../../assets/homeIcons/navbarIcon.png';
import instagram from '../../assets/homeIcons/instagram.svg';
import facebook from '../../assets/homeIcons/facebook.svg';
import twitter from '../../assets/homeIcons/twitter.svg';
import linkdin from '../../assets/homeIcons/linkdin.svg';
import { AppContext } from '../../context';
import { ReactComponent as Hamburger } from '../../assets/homeIcons/hamburger.svg';
import { ReactComponent as Hamburger2 } from '../../assets/homeIcons/hamburger2.svg';
import withSectionIds from '../containers/withSectionIds';

const CustomNavLink = ({ to: link, children }) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <NavLink
      to={link}
      className="flex flex-col justify-center items-center text-xl text-white"
    >
      {children}
      {isActive && (
        <span className="absolute mt-[37px] w-[94px] h-[1px] bg-white rounded-full transition-all duration-300" />
      )}
    </NavLink>
  );
};

const Navbar = ({ checkIsBlackColor }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const scrollY = useContext(AppContext);

  return (
    <div>
      <header
        id="header"
        className={cx(
          'fixed w-full py-5 bg-black/20 flex justify-around items-center backdrop-blur z-50 transition-all duration-300 ',
          {
            '-translate-y-full': scrollY,
            '!bg-black': location.pathname !== '/',
          }
        )}
      >
        <div className="">
          <img src={vmaLogo} alt="logo" className="opacity-0" />
        </div>
        <div className="flex items-center pl-4">
          <div className="hidden lg:flex gap-5 lg:gap-20 text-white w-auto ">
            <CustomNavLink to="/">Home</CustomNavLink>
            <CustomNavLink to="/services">Service</CustomNavLink>
            <CustomNavLink to="/careers">Careers</CustomNavLink>
          </div>
        </div>
      </header>
      <div
        className={cx(
          'fixed top-[22px] md:top-[25px] left-[25px] md:left-[70px] z-50 cursor-pointer',
          { absolute: location.pathname !== '/' }
        )}
        onClick={() => {
          if (location.pathname !== '/')
            navigate('/', () => {
              animateScroll.scrollToTop();
            });
          else animateScroll.scrollToTop();
        }}
      >
        <img
          // if we are on the top position and the sections are with light color should render blue color
          // else the image color will be white
          src={
            (checkIsBlackColor() || location.pathname !== '/') && scrollY !== 0
              ? blueLogo
              : vmaLogo
          }
          alt="logo"
          className="w-[60%] md:w-full"
        />
      </div>

      <div
        className={cx(
          'flex gap-5 items-center fixed z-50 right-[29px] lg:right-[10px] xl:right-[15px] top-[22px]',
          { absolute: location.pathname !== '/' }
        )}
      >
        <div className="group transition-all duration-300 z-50">
          <div className="relative">
            <img
              src={navbarIcon}
              alt="connection"
              className="group-hover:opacity-0 transition-all duration-300 w-[24px] lg:w-auto"
            />

            <div className="flex flex-col gap-3 bg-black/80 p-3 transition-all duration-[.5s] ease-in-out  group-hover:h-auto scale-y-0 origin-top  group-hover:scale-y-100 opacity-0 group-hover:opacity-100 absolute -right-[7px] lg:right-[2px] top-1">
              <Link
                to="https://www.instagram.com/vma_tech_labs/"
                target="_blank"
                className="w-[18px]"
              >
                <img src={instagram} alt="instagram" />
              </Link>
              <Link
                to="https://www.facebook.com/VMAtechlabs/"
                target="_blank"
                className="w-[18px]"
              >
                <img src={facebook} alt="facebook" />
              </Link>
              <Link
                to="https://twitter.com/VMA_Tech_Labs"
                target="_blank"
                className="w-[18px]"
              >
                <img src={twitter} alt="twitter" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/vma-tech-labs/"
                target="_blank"
                className="w-[18px]"
              >
                <img src={linkdin} alt="linkdin" />
              </Link>
            </div>
          </div>
        </div>
        <Hamburger
          className="lg:hidden"
          onClick={() => setIsNavOpen((prev) => !prev)}
        />
      </div>

      {isNavOpen && (
        <div className="fixed z-50 right-0 top-0 w-full bg-white">
          <div className="w-full relative">
            <div
              className="absolute top-[34px] right-[28px]"
              onClick={() => setIsNavOpen(false)}
            >
              <Hamburger2 />
            </div>
            <div className="flex flex-col gap-3 pt-28 pr-8 pb-10">
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  borderColor: isActive ? 'black' : '',
                  borderBottomWidth: isActive ? '2px' : '',
                })}
                className="text-black text-right text-xl w-fit place-self-end"
              >
                Home
              </NavLink>
              <NavLink
                to="/services"
                style={({ isActive }) => ({
                  borderColor: isActive ? 'black' : '',
                  borderBottomWidth: isActive ? '2px' : '',
                })}
                className="text-black text-right text-xl w-fit place-self-end"
              >
                Service
              </NavLink>
              <NavLink
                to="/careers"
                style={({ isActive }) => ({
                  borderColor: isActive ? 'black' : '',
                  borderBottomWidth: isActive ? '2px' : '',
                })}
                className="text-black text-right text-xl w-fit place-self-end"
              >
                Careers
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withSectionIds(Navbar);
