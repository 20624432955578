import React, { useState } from 'react';
import { Formik } from 'formik';
import Button from '../common/button';
import formSideImg from '../../../src/assets/images/formSideImg.png';
import messageIcon from '../../assets/Icon/messageIcon.png';
import contactIcon from '../../assets/Icon/contactIcon.png';
import ContactModal from './contactModal';
import { useEffect } from 'react';
import cx from 'classnames';

function FormContactpage({ dark }) {
  const [contactModal, setContactModal] = useState(false);
  const openModal = () => {
    setContactModal(true);
  };
  const closeModal = () => {
    setContactModal(false);
  };

  useEffect(() => {
    if (contactModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, []);

  return (
    <div id="contactus" className="relative p-5">
      <div className="flex flex-col sm:flex-row justify-center my-8">
        <div className="w-full sm:w-auto text-white bg-black shadow-md rounded-lg grid md:grid-cols-2 p-6 py-10 gap-4 sm:gap-16">
          <div className="flex justify-center items-center gap-2 sm:gap-4">
            <img src={messageIcon} alt="messageIcon" className="w-9 sm:w-11" />
            <div className="text-base md:text-xl lg:text-2xl font-medium text-white flex items-center gap-2 sm:gap-4">
              Email Us:
              <a
                href="mailto:info@vmatechlabs.com"
                className="text-base sm:text-lg"
              >
                info@vmatechlabs.com
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center gap-2 sm:gap-4">
            <img src={contactIcon} alt="contactIcon" className="w-7 sm:w-9" />
            <div className="gap-1 sm:gap-3 flex text-base sm:text-xl lg:text-2xl">
              Contact Us
              <a href="tel:7981859750" className="text-base sm:text-lg">
                7981859750
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'grid md:grid-cols-10 md:w-[90%] mx-auto bg-white drop-shadow-lg rounded-xl overflow-hidden',
          {
            '!bg-black': dark,
          }
        )}
      >
        <div
          className="col-span-full md:col-span-3 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${formSideImg})`,
            backgroundPosition: 'right',
          }}
        >
          <img src={formSideImg} className="w-full object-contain opacity-0" />
        </div>
        <div className="col-span-full md:col-span-7 py-6 px-2 sm:px-6">
          <Formik
            initialValues={{
              user_name: '',
              email_id: '',
              phone_number: '',
              subject: '',
              notes: '',
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email_id) {
                errors.email_id = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                  values.email_id
                )
              ) {
                errors.email_id = 'Invalid email address';
              }

              if (!values.user_name) {
                errors.user_name = 'Please enter your name.';
              }

              if (!values.phone_number) {
                errors.phone_number = 'Please enter your mobile number.';
              } else if (
                !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(
                  values.phone_number
                )
              ) {
                errors.phone_number = 'Please enter valid number.';
              }

              if (!values.subject) {
                errors.subject = 'Please enter your subject.';
              }

              if (!values.notes) {
                errors.notes = 'Please enter a message.';
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm }) => {
              try {
                const response = await fetch(
                  'https://vmatechlabs.com:5010/v1/api/user/create',
                  {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                    },
                  }
                );

                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }

                const json = await response.json();

                if (json.success) {
                  openModal();
                }
              } catch (error) {
                console.error('Error:', error);
              }
              resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid lg:grid-cols-2 mt-4 gap-3 gap-x-5">
                  <div className="flex flex-col">
                    <input
                      className={cx(
                        'border border-slate-300  rounded-lg bg-[#FFFFFF] w-full p-4 px-5 sm:px-7 sm:text-[22px] font-semibold outline-none',
                        {
                          'bg-black text-white': dark,
                        }
                      )}
                      placeholder="Your Name"
                      name="user_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user_name}
                    />
                    <span className="text-red-600 text-sm font-light">
                      {errors.user_name &&
                        touched.user_name &&
                        errors.user_name}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <input
                      className={cx(
                        'border border-slate-300  rounded-lg bg-[#FFFFFF] w-full p-4 px-5 sm:px-7 sm:text-[22px] font-semibold outline-none',
                        {
                          'bg-black text-white': dark,
                        }
                      )}
                      placeholder="Your email"
                      name="email_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email_id}
                    />
                    <span className="text-red-600 text-sm font-light">
                      {errors.email_id && touched.email_id && errors.email_id}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <input
                      className={cx(
                        'border border-slate-300  rounded-lg bg-[#FFFFFF] w-full p-4 px-5 sm:px-7 sm:text-[22px] font-semibold outline-none',
                        {
                          'bg-black text-white': dark,
                        }
                      )}
                      placeholder="Your Number"
                      name="phone_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_number}
                    />
                    <span className="text-red-600 text-sm font-light">
                      {errors.phone_number &&
                        touched.phone_number &&
                        errors.phone_number}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <input
                      className={cx(
                        'border border-slate-300  rounded-lg bg-[#FFFFFF] w-full p-4 px-5 sm:px-7 sm:text-[22px] font-semibold outline-none',
                        {
                          'bg-black text-white': dark,
                        }
                      )}
                      placeholder="Subject"
                      name="subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    <span className="text-red-600 text-sm font-light">
                      {errors.subject && touched.subject && errors.subject}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col my-3">
                  <div className="flex flex-col">
                    <div
                      className={cx(
                        'py-2  border border-slate-300 bg-[#FFFFFF] rounded-lg md:w-full',
                        {
                          'bg-black text-white': dark,
                        }
                      )}
                    >
                      <textarea
                        id="teaxtarea"
                        name="notes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.notes}
                        placeholder="Message"
                        rows="4"
                        className={cx(
                          'outline-none w-full p-5 px-6 sm:text-[22px] font-semibold',
                          {
                            'bg-black text-white': dark,
                          }
                        )}
                      />
                    </div>
                    <span className="text-red-600 text-sm font-light">
                      {errors.notes && touched.notes && errors.notes}
                    </span>
                  </div>
                  <div className="flex justify-start items-center pt-5">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className={cx(
                        'flex justify-center items-center text-white drop-shadow-2xl bg-black px-10 sm:px-14 py-4 rounded-lg',
                        {
                          'bg-white !text-black': dark,
                        }
                      )}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {contactModal && (
        <ContactModal closeModal={closeModal} contactModal={contactModal} />
      )}
    </div>
  );
}

export default FormContactpage;
