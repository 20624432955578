import React from 'react';
import img1 from '../../assets/e-invoice/Rectangle 239.png';
import img2 from '../../assets/e-invoice/Rectangle 6581.png';
import img3 from '../../assets/e-invoice/Rectangle 241.png';
import img4 from '../../assets/e-invoice/Rectangle 242.png';
import img5 from '../../assets/e-invoice/Rectangle 243.png';
import cx from 'classnames';

const InvoiceFeature = () => {
  const contentMatter = [
    {
      title: 'Seamless Integration',
      text: 'Effortlessly connect and integrate e-invoicing capabilities with various ERP systems used across your organization.',
      mainImg: img1,
    },
    {
      title: 'Multi-ERP Compatibility',
      text: 'This solution is designed to work with a range of ERP platforms, ensuring flexibility and compatibility with your existing software.',
      mainImg: img2,
    },
    {
      title: 'Centralized Invoicing',
      text: 'Consolidate invoicing processes across all ERPs into a centralized system for improved efficiency and control.',
      mainImg: img3,
    },
    {
      title: 'Automated Data Sync',
      text: 'Real-time data synchronization between ERP systems and the e-invoicing solution, reducing manual data entry and errors.',
      mainImg: img4,
    },
    {
      title: 'Compliance Management',
      text: 'Stay compliant with invoicing regulations across different regions or countries, with the solution adapting to diverse tax and legal requirements.',
      mainImg: img5,
    },
  ];
  return (
    <>
      <div className="bg-white max-w-[1200px] m-auto py-1 sm:py-5">
        <div className="text-center font-sembold">
          <h2 className="text-[23px] sm:text-[40px] py-1 sm:py-3 font-semibold">
            Key features of E-Invoice
          </h2>
        </div>

        <div className="max-w-[1180px] mx-auto p-1">
          {contentMatter.map(({ title, text, mainImg }, index) => (
            <div
              key={index}
              className={cx('sm:flex gap-5  mb-5 flex-row ', {
                'flex-row-reverse': index % 2 !== 0,
              })}
            >
              <div
                className={cx(
                  'flex-1 justify-center items-center m-2 py-2 sm:py-9 lg:py-20 xl:py-28',
                  {
                    ' text-left sm:text-right': index % 2 !== 0,
                  }
                )}
              >
                <h2 className="text-[20px] md:text-[25px] lg:text-[30px] font-medium">
                  {title}
                </h2>
                <p className="text-[14px] md:text-[18px] lg:text-[22px] leading-relaxed py-3">
                  {text}
                </p>
              </div>
              <div className="relative w-full sm:w-1/2 ">
                <img src={mainImg} alt="features" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InvoiceFeature;
