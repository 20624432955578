import React from 'react';
// import backgroundimg from '../../assets/e-invoice/MicrosoftTeams-image.png'
import backgroundimg from '../../assets/e-invoice/envoice2.jpg';
const Integration = () => {
  return (
    <>
      <div className="relative min-h-[50vh] ">
        <div className="absolute inset-0 bg-[#353535]/40" />
        <img
          src={backgroundimg}
          alt="background"
          className="w-full h-[70vh] sm:h-[95vh] object-cover "
        />
        <div className="absolute  left-[6px] sm:left-[66px] bg-transparent top-[250px]   sm:w-1/2  ">
          <h1 className="text-white text-[16px] sm:text-[27px] lg:text-[34px]">
            E-Invoice Integration Software
          </h1>
          <p className="text-white  text-[12px] sm:text-[18px] lg:text-[24px] py-2">
            Our E-Invoice Integration Solution simplifies and streamlines the
            electronic invoicing process for businesses using multiple ERP
            (Enterprise Resource Planning) systems.
          </p>
        </div>
      </div>
    </>
  );
};

export default Integration;
