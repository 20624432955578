import React from 'react';
import Layout from '../components/layout';
import Contact from '../components/contact/contact';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.vmatechlabs.com/contact" />
      </Helmet>
      <Layout>
        <Contact />
      </Layout>
    </>
  );
};

export default ContactPage;
