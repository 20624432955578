import React from 'react';
import DigitalSignature from '../components/digitalSignature/digitalSignature';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import Offers from '../components/digitalSignature/offers';
import Details from '../components/digitalSignature/details';
import FormContactpage from '../components/contact/formContactpage';

const Digitalsign = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/digitalSign" />
      </Helmet>
      <Layout>
        <DigitalSignature />
        <Offers />
        <Details />
        <FormContactpage />
      </Layout>
    </>
  );
};

export default Digitalsign;
