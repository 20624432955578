import React from 'react';
import SubtractImg from '../../../src/assets/careerImages/SubtractImg.png';
import SectionTtitle from '../common/sectionTtitle';
import { Link } from 'react-router-dom';

const HomeCareerSection = () => {
  return (
    <section id="career">
      <SectionTtitle
        className="pt-10"
        title="Career"
        description="Career with VMA labs: Shape the Future, Innovate with Passion!"
      />
      <div className="flex justify-center items-center">
        <div className="relative corner-border w-[90%]">
          <div className="lg:grid grid-cols-4 gap-0 overflow-hidden">
            <div className="col-span-1 text-white bg-black flex justify-center align-middle">
              <div className="flex flex-col justify-center items-center p-3">
                <div className="text-center text-sm sm:text-lg lg:text-[26px] font-medium">
                  <h1>Know Your Potential</h1>
                </div>
                <div className="text-center text-[#FFFFFF] text-sm sm:text-xl pt-3">
                  <p>
                    Unlock your potential at VMA labs. Join our team, work on
                    impactful projects, and collaborate in a supportive
                    environment. Take the leap and apply now!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-3 w-full h-full overflow-hidden">
              <img
                src={SubtractImg}
                alt="back"
                className="w-full h-full scale-[1.1]"
              />
            </div>
          </div>
          <Link
            to="career"
            className="absolute right-[80px] bottom-0 z-10 bg-[#D9D9D9] p-[8px]"
          >
            <button className="bg-black text-[#FFFFFF] px-8 py-3 border-l-2 border-b-2 border-white w-full ">
              Career
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeCareerSection;
