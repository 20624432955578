import React, { useState } from 'react';

function ImageUpload() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setSelectedImage(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <input type="file" onChange={handleImageUpload} />

      {selectedImage && (
        <div>
          <img
            src={selectedImage}
            alt="Uploaded"
            width="200"
            height="200"
            className="mt-6"
          />
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
