import React from 'react';
import sap from '../../assets/careerImages/sap.png';
import awarded from '../../assets/careerImages/awarded.png';
import center from '../../assets/careerImages/center.png';
import dedication from '../../assets/careerImages/dedication.png';
import SectionTtitle from '../common/sectionTtitle';

const Opportunities = () => {
  return (
    <div className="bg-white p-5">
      <SectionTtitle
        title="Lets have a Walk"
        description="VMA LABS is an innovative management and technology consulting
        firm. We provide our Clients a comprehensive suite of systems
        integration"
      />
      <div className="flex gap-5 md:w-[90%] m-auto items-center py-4 text-[13px] md:text-2xl text-[#000000] font-semibold">
        Opportunities That We Provide You
        <div className="flex gap-3">
          <span className=" opacity-[0.3] flex align-middle">\</span>
          <span className=" opacity-[0.6]  flex align-middle">\</span>
          <span className=" opacity-[0.8]  flex align-middle">\</span>
        </div>
      </div>

      <div className="relative grid lg:flex gap-[2px] rounded-lg md:w-[90%] m-auto">
        <div className="grid md:flex gap-[2px]">
          {/* First image */}
          <div className="group relative overflow-hidden">
            <img
              src={sap}
              alt="sap"
              className="w-full object-contain group-hover:scale-110 transition-all duration-300"
            />
            <div className="text-center absolute bottom-0 translate-y-[50px] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <p className="text-white xl:text-xl">
                Cutting-edge Projects: Dive into exciting projects at VMA
                Techlabs, where you'll work on the forefront of technology,
                pushing boundaries and transforming industries.
              </p>
            </div>
          </div>

          {/* second image */}
          <div className="group relative overflow-hidden">
            <img
              src={awarded}
              alt="award"
              className="w-full group-hover:scale-110 transition-all duration-300 object-contain"
            />
            <div className="text-center absolute bottom-0 translate-y-[50px] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <p className="text-white xl:text-xl">
                Collaborative Culture: Join a vibrant and inclusive team,
                fostering collaboration and creativity, where diverse
                perspectives thrive, and collective brilliance drives
                groundbreaking solutions.
              </p>
            </div>
          </div>
        </div>
        {/* third and fourth image */}
        <div className="relative">
          <div className="group relative overflow-hidden">
            <img
              src={center}
              alt="center"
              className="w-full object-contain group-hover:scale-110 transition-all duration-300"
            />
            <div className="text-center absolute bottom-0 translate-y-[50px] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear ">
              <p className="text-white xl:text-xl">
                Impactful Innovation: Contribute to game- changing innovations
                that make a real -world impact, creating a better future through
                revolutionary technologies developed at VMA labs.
              </p>
            </div>
          </div>

          <div className="group relative overflow-hidden">
            <img
              src={dedication}
              alt="dedication"
              className="w-full object-contain group-hover:scale-110 transition-all duration-300"
            />
            <div className="text-center absolute bottom-0 translate-y-[50px] md:translate-y-[22px] lg:translate-y-[50px] group-hover:translate-y-0 w-full transition-all duration-300 ease-linear">
              <p className="text-white xl:text-xl">
                Career Growth: Fuel your professional growth with ample
                opportunities for advancement, mentorship programs, and
                continuous learning to help you excel in your chosen field.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opportunities;
