import React from 'react';
import Layout from '../components/layout';
import OurExcellence from '../components/about/ourExcellence';
import ModalOfApproach from '../components/about/ourModalOfApproach';
import ClientsFeedback from '../components/about/ourClientsFeedback';
import Statistics from '../components/about/statistics';
import Services from '../components/about/services';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/about" />
      </Helmet>
      <Layout>
        <div className="pt-20">
          <OurExcellence />
          <Services />
          <ModalOfApproach />
          <ClientsFeedback />
          <Statistics />
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;
