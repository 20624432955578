import React from 'react';
import careerbackground from '../../../src/assets/careerImages/careerbackground.png';

const CareerBackground = () => {
  return (
    <div className="relative">
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-[#353535]/80" />
        <div className="conic-gradient absolute inset-0 h-full origin-center" />
        <img
          src={careerbackground}
          alt="background"
          className="w-full min-h-[70vh] object-cover"
        />
      </div>
      <div className="absolute inset-y-0 left-[66px] bg-transparent top-[118px]">
        <span className="text-white text-3xl lg:text-7xl">
          If You Can <b>DREAM</b> It
        </span>
        <p className="py-4">
          <span className="text-white mt-3 text-3xl lg:text-7xl">
            You Can <b>DO</b> It
          </span>
        </p>
        <div className="md:pt-4">
          <button className="bg-white border-white border-2 text-black text-[16px] md:text-[20px] font-medium py-3 px-7 transition-all duration-500 hover:translate-x-[4px] hover:translate-y-[-3px] hover:shadow-[-4px_3px] !shadow-white/70">
            Openings
          </button>
        </div>
      </div>
      <div className="text-center w-full absolute bottom-[3px] text-4xl lg:text-7xl font-bold  text-white opacity-30">
        FIND YOUR POTENTIAL WITH US
      </div>
    </div>
  );
};

export default CareerBackground;
