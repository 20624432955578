import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const SitemapPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/privacy" />
      </Helmet>
      <Layout>
        <div className="ml-[8%] pt-[15%] text-[20px] pb-4">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/careers">Careers</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/terms">Terms of service</Link>
          </li>
          <li>
            <Link to="/privacy">privacy policy</Link>
          </li>
          <li>
            <Link to="/products/vsync">Vsync</Link>
          </li>
          <li>
            <Link to="/e-invoice">E Invoice</Link>
          </li>
        </div>
      </Layout>
    </>
  );
};

export default SitemapPage;
