import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import exploreImg from '../../../src/assets/images/exploreImg.png';
import SectionTtitle from '../common/sectionTtitle';

const ExploreHome = () => {
  const navigate = useNavigate();
  return (
    <section id="explore" className="bg-[#FDD07C] p-5">
      <SectionTtitle
        title="Power Your Preparedness"
        description="Our core capabilities ensure effective response in emergencies.
        Harenessing essential elements, safeguarding your business when it
        matters."
        className="mt-5"
      />
      <div className="flex flex-col-reverse md:grid grid-cols-2  border-t-[5px] md:border-t-0 border-r-[5px] border-b-[5px] border-white border-opacity-60 md:border-white min-h-[47vh]  mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-10 gap-4 p-4">
          <div className="hidden md:block bg-white bg-opacity-20 w-[80%] h-full col-span-2  md:rounded-lg "></div>
          <div className="hidden md:block bg-white bg-opacity-40 w-[80%] h-full col-span-3  md:rounded-lg "></div>

          <div className="col-span-4 flex justify-center items-center relative">
            <div className="bg-white md:rounded-lg absolute top-0 left-0 h-full w-[90%]"></div>
            <img src={exploreImg} alt="explore" className="h-full opacity-0" />
            <div className="flex justify-center items-center absolute z-20  bottom-0 h-full">
              <img
                src={exploreImg}
                alt="explore"
                className="md:min-w-[380px] lg:min-w-[418px] h-full lg: object-contain"
              />
            </div>
          </div>
        </div>
        <div className="md:border-t-[5px] md:border-white pr-5 pl-3 xl:mb-40">
          <p className="text-sm font-medium sm:text-xl pt-5 sm:pt-20">
            Transform your business with VMA Labs'team of 5+ world class experts
            at our Dedicated Centre of Excellence,driving innovation and
            delivering exceptional results.
          </p>
          <Link to="*" className="text-sm font-bold sm:text-[22px]">
            Learn More
          </Link>
          <div className="hidden md:block py-8 pl-5">
            <button
              className="flex justify-center items-center text-[#000000] bg-[#F2F1F1] border-l-[2px] border-[#000000] border-b-[2px] py-4 px-8 text-sm sm:text-xl"
              onClick={() => navigate('under-maintainance')}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end md:hidden py-8 pl-5">
        <button
          className="flex justify-center items-center text-[#000000] bg-[#F2F1F1] border-l-[2px] border-[#000000] border-b-[2px] py-4 px-8 text-sm sm:text-xl"
          onClick={() => navigate('explore')}
        >
          Explore
        </button>
      </div>
    </section>
  );
};

export default ExploreHome;
