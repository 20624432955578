import React from 'react';
import threeIcon from '../../../src/assets/services/threeIcon.png';
import birlaIcon from '../../../src/assets/services/birla.png';
import capriconIcon from '../../../src/assets/services/capricon.png';
import ddusIcon from '../../../src/assets/services/ddusImg.png';
import indigoIcon from '../../../src/assets/services/indigoLogo.png';
import gapbridgeIcon from '../../../src/assets/services/gapbridge.png';
import heteroIcon from '../../../src/assets/services/heteroLogo.png';
import eimIcon from '../../../src/assets/services/eimImg.png';
import itcIcon from '../../../src/assets/services/itcLogo.png';
import toyotaIcon from '../../../src/assets/services/toyotaLogo.png';
import sherbazarIcon from '../../../src/assets/services/sherbazar.png';
import nedvaticIcon from '../../../src/assets/services/nedvaticLogo.png';
import SectionTtitle from '../common/sectionTtitle';

const ServiceCards = () => {
  let clients = [
    {
      image: threeIcon,
      name: 'Three Logo',
    },
    {
      image: birlaIcon,
      name: 'Birla Logo',
    },
    {
      image: capriconIcon,
      name: 'Capricorn',
    },
    {
      image: ddusIcon,
      name: 'Ddus logo',
    },
    {
      image: indigoIcon,
      name: 'Indigo Logo',
    },
    {
      image: gapbridgeIcon,
      name: 'Gapbridge Logo',
    },
    {
      image: heteroIcon,
      name: 'Hater Logo',
    },
    {
      image: eimIcon,
      name: 'EIM Logo',
    },
    {
      image: itcIcon,
      name: 'ITC logo',
    },
    {
      image: toyotaIcon,
      name: 'Toyota logo',
    },
    {
      image: sherbazarIcon,
      name: 'Share Bazar logo',
    },
    {
      image: nedvaticIcon,
      name: 'Nedvatic logo',
    },
  ];
  return (
    <>
      <div className="">
        <SectionTtitle title="FEW OF OUR ESTEEMED CUSTOMERS" />
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-4">
          {clients.map(({ name, image }) => (
            <div
              key={name}
              className="flex justify-center h-[85px] sm:h-[140px] bg-white border shadow-lg rounded-2xl p-3 sm:px-5"
            >
              <img src={image} alt={name} className="object-contain w-[90%]" />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
