import React from 'react';
import Layout from '../components/layout';
import TermSection from '../components/termSection/termSection';
import { Helmet } from 'react-helmet';

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="3FhzHf8O88e6vDvPgDjjJB6aeCYT2LcKn6lWosdsHuo"
        />
        <link rel="canonical" href="https://www.vmatechlabs.com/terms" />
      </Helmet>
      <Layout>
        <TermSection />
      </Layout>
    </>
  );
};

export default TermsPage;
