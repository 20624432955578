import React from 'react';
import navigation from '../../assets/Icon/navigation.png';
import SectionTtitle from '../common/sectionTtitle';
import FormContactpage from './formContactpage';

const Contact = () => {
  const addressData = [
    {
      title: 'Our Address At Bangalore',
      address:
        '578 4th Floor, Service Rd, Santhosapuram, Venkatapura, Koramangala, Bengaluru, Karnataka 560034',
    },
    {
      title: 'Our Address At Hydrabad',
      address:
        'No.57, 2nd floor, Plot, 2A, Hitech City Rd, VIP Hills, Jaihind Enclave, Madhapur, Hyderabad, Telangana 500081',
    },
    {
      title: 'Our Address At Gurugram',
      address:
        '509, Fifth Floor, JMD Pacific Square Sector – 15, Gurugram, 122001.',
    },
  ];
  return (
    <>
      <SectionTtitle
        className="pt-28 pb-2"
        title="Let's Talk"
        description="We are just a form away whether it is managed infrastructure or a need
          for application development, we can effectively strategize and implement your digital
          transformation. So, tell us your need and know how we can address them. After all, we are only a form away"
      />

      <div className="bg-white drop-shadow-lg w-[90%] mx-auto rounded-[12px]">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 p-5">
          {addressData?.map(({ title, address }) => (
            <div className="flex flex-col justify-stretch items-center">
              <img src={navigation} alt="icon" className="w-16" />
              <div className="text-center text-[#000000] text-lg sm:text-2xl font-medium py-2 whitespace-nowrap">
                {title}
              </div>
              <div className="text-center text-sm sm:text-lg">{address}</div>
            </div>
          ))}
        </div>
      </div>

      <FormContactpage />
      <div className="bg-white p-4 my-10">
        <div className="rounded-md">
          <iframe
            className="w-full lg:w-[90%] mx-auto h-full rounded-xl drop-shadow-2xl min-h-[70vh]"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8668741301203!2d77.63522041482135!3d12.91627669089219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae159fcb64c527%3A0x87ab63b626c33505!2sVMA%20techlabs!5e0!3m2!1sen!2sin!4v1665642015387!5m2!1sen!2sin"
            allowFullScreen
            loading="lazy"
            title="Map Embed"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
