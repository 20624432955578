import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.css';

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    centerPadding: '0px',
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3, 
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2, 
        },
      },
      {
        breakpoint: 320, 
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
  };

  const item = [
    'first stage',
    'second stage',
    'third stage',
    'fourth stage',
    'fifth stage',
    'final stage',
  ];

  return (
    <Slider className="" {...settings}>
      {item.map((items, index) => (
        <div className="py-2 !w-auto relative" key={index}>
          <div className="relative w-40 h-40  bg-white flex items-center justify-center rounded-full border-gradient-white-blue border">
            <div className="text-[#000000] text-2xl font-medium w-[50%] mx-auto text-center">
              {items}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
