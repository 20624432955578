import React, { useCallback, useEffect, useRef } from 'react';
import mail from '../../assets/Icon/mailIcon.png';
import phone from '../../assets/Icon/phoneIcon.png';
import cancelBtn from '../../assets/Icon/cancelIcon.png';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Button from '../common/button';
import cx from 'classnames';

const FreeQuoteModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    },
    [onClose]
  );

  const handleEscape = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose, handleClickOutside, handleEscape]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="">
        <div className="fixed inset-0 flex items-center justify-center z-50  bg-black bg-opacity-80">
          <div
            ref={modalRef}
            className="modal-container md:w-[70%] bg-[#242424] px-8  max-h-full overflow-y-scroll md:overflow-y-auto"
          >
            <div className="float-right mt-12 md:mt-4" onClick={onClose}>
              <img
                src={cancelBtn}
                alt="cancel button"
                className="cursor-pointer"
              />
            </div>
            <div className="text-white text-center py-8 text-[30px]">
              GET A FREE QUOTE
            </div>
            <Formik
              initialValues={{
                name: '',
                phone: '',
                email: '',
                purpose: '',
                message: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }

                if (!values.name) {
                  errors.name = 'Please enter your name.';
                }

                if (!values.phone) {
                  errors.phone = 'Please enter your mobile number';
                } else if (
                  !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(
                    values.phone
                  )
                ) {
                  errors.phone = 'Please enter valid number.';
                }
                if (!values.purpose) {
                  errors.purpose = 'Please enter your purpose.';
                }

                if (!values.message) {
                  errors.message = 'Please enter a message.';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-6 gap-12">
                    <div className="col-span-full lg:col-span-3">
                      <>
                        <div className="grid grid-cols-4 mb-4">
                          <label
                            htmlFor="name"
                            className="!text-white font-medium"
                          >
                            Name
                          </label>
                          <div className="col-span-3 flex flex-col">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              className="bg-[#6B6B6B] h-[30px] w-full  text-white px-3 py-2"
                            />
                            <span
                              className={cx('opacity-0 text-red-600 text-xs', {
                                'opacity-100': errors.name,
                              })}
                            >
                              Please enter your name
                            </span>
                          </div>
                        </div>
                      </>
                      <div className="grid grid-cols-4 mb-4">
                        <label
                          htmlFor="phone"
                          className="!text-white font-medium"
                        >
                          Phone
                        </label>
                        <div className="col-span-3 flex flex-col">
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="w-full h-[30px] bg-[#6B6B6B] px-3 py-2 "
                          />
                          <span
                            className={cx('opacity-0 text-red-600 text-xs ', {
                              ' opacity-100':
                                errors.phone && touched.phone && errors.phone,
                            })}
                          >
                            {errors.phone || 'Please enter your mobile number.'}
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 mb-4">
                        <label
                          htmlFor="email"
                          className=" !text-white font-medium "
                        >
                          Email
                        </label>

                        <div className="col-span-3 flex flex-col">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="w-full h-[30px] bg-[#6B6B6B] px-3 py-2"
                          />
                          <span
                            className={cx('text-red-600 text-xs  opacity-0', {
                              'opacity-100':
                                errors.email && touched.email && errors.email,
                            })}
                          >
                            {errors.email || 'Please enter your email.'}
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 mb-8">
                        <label
                          htmlFor="website"
                          className="!text-white font-medium"
                        >
                          Website
                        </label>

                        <div className="col-span-3">
                          <input
                            type="tel"
                            id="website"
                            name="website"
                            className="w-full h-[30px] bg-[#6B6B6B] px-3 py-2"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-4 mb-4">
                        <label
                          htmlFor="purpose"
                          className="!text-white font-medium"
                        >
                          Purpose
                        </label>

                        <div className="col-span-3 flex flex-col">
                          <input
                            type="tel"
                            id="purpose"
                            name="purpose"
                            value={values.purpose}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="w-full h-[30px] bg-[#6B6B6B] px-3 py-2"
                          />
                          <span
                            className={cx('text-red-600 text-xs  opacity-0', {
                              'opacity-100':
                                errors.purpose &&
                                touched.purpose &&
                                errors.purpose,
                            })}
                          >
                            Please enter your purpose
                          </span>
                        </div>
                      </div>

                      <div className="grid grid-cols-4 mb-4">
                        <label
                          htmlFor="message"
                          className="!text-white font-medium whitespace-nowrap"
                        >
                          Message
                        </label>

                        <div className="col-span-3 flex flex-col">
                          <input
                            type="tel"
                            id="message"
                            name="message"
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="bg-[#6B6B6B] h-[30px] w-full px-3 py-2"
                          />
                          <span
                            className={cx('text-red-600 text-xs  opacity-0', {
                              'opacity-100':
                                errors.message &&
                                touched.message &&
                                errors.message,
                            })}
                          >
                            Please enter a message
                          </span>
                        </div>
                      </div>

                      <div className="flex justify-end py-4">
                        <Button
                          type="submit"
                          className="flex justify-center items-center bg-white drop-shadow-lg text-black px-5 sm:px-14 py-3"
                        >
                          SEND
                        </Button>
                      </div>
                    </div>
                    <div className="col-span-full lg:col-span-3  text-center ">
                      <h1 className="text-4xl text-white mb-2">
                        What's Next ?
                      </h1>
                      <div className="lg:border-l pl-3 h-1/2">
                        <p className="text-white mb-6">
                          An email and a phone call from one of us
                        </p>
                        <div className="bg-[#373737] border flex flex-wrap w-[50%]  ml-[23%] p-2 justify-center gap-2 mb-6 ">
                          <div>
                            <img src={phone} alt="phone icon" />
                          </div>
                          <div className="text-white">7981859750</div>
                        </div>
                        <div className="bg-[#373737] border flex flex-wrap justify-center gap-2 mt-4 p-4 mb-6 ">
                          <img src={mail} alt="mail icon" />
                          <Link
                            to="/services"
                            className="text-white flex flex-wrap"
                          >
                            info@vmatechlabs.com
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeQuoteModal;
