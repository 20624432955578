import React, { useContext } from 'react';
import scrollTopIcon from '../../assets/homeIcons/scrollTopIcon.svg';
import { AppContext } from '../../context';
import cx from 'classnames';

const ScrollTop = ({ onClick }) => {
  const scrollY = useContext(AppContext);
  return (
    <div
      className={cx(
        'fixed z-50 bottom-[30px] md:bottom-[40px] right-[30px] md:right-[50px] cursor-pointer border border-black rounded-full',
        {
          hidden: scrollY <= 400,
        }
      )}
      onClick={onClick}
    >
      <img
        src={scrollTopIcon}
        alt="scroll"
        className="w-[40px] sm:w-[50px] drop-shadow-2xl"
      />
    </div>
  );
};

export default ScrollTop;
