import React from 'react';
import Button from '../common/button';
import scroll from '../../assets/digitalSign/scrollIcon.svg';
import { Link } from 'react-scroll';

const DigitalSignature = () => {
  return (
    <>
      <div className="digitalBackground">
        <div className="pt-[140px] w-[88%] mx-auto">
          <div className="text-[18px] md:text-[22px]">
            Featured product from VMA
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-white text-[22px] md:text-[44px] pt-8 lg:pt-16">
              Digital Signature
            </div>
            <div className="lg:w-[70%] xl:w-[50%] text-[14px] md:text-[20px] text-[#D7D7D7] pt-8">
              Our Integration Solution for Multiple ERP systems is a
              comprehensive solution designed to streamline, automation and
              enhance the security of document signing processes across various
              ERP platforms.
            </div>
            <div className="py-4 md:pt-12">
              <Link to="contactus" smooth={true}>
                <Button className="bg-white h-[50px] md:h-[60px] w-[150px] md:w-[180px] text-[18px] md:text-[22px]">
                  contact us
                </Button>
              </Link>
            </div>
          </div>
          <div className="hidden md:block pb-4">
            <div className="flex justify-end p-2 pt-4 md:pt-0">
              <div className="bg-[#262626] w-1/2 p-2">
                <Link to="offers" smooth={true}>
                  <img
                    className="h-10 cursor-pointer pt-2"
                    src={scroll}
                    alt="scrollIcon"
                  />
                  <div className="text-white text-end mr-3 cursor-pointer">
                    View More
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalSignature;
