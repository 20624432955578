import React from 'react';
import img1 from '../../../assets/products/Rectangle 6636.svg';
import img2 from '../../../assets/products/Rectangle 6639.svg';
import img3 from '../../../assets/products/Rectangle 6640.svg';
import img4 from '../../../assets/products/Rectangle 6641.svg';
import icon1 from '../../../assets/map imges/Vector (2).png';
import icon2 from '../../../assets/map imges/Vector (4).png';
import icon3 from '../../../assets/map imges/iconamoon_headphone-light.png';
import icon4 from '../../../assets/map imges/Vector (3).png';
import cx from 'classnames';

const Utility = () => {
  const contentMatter = [
    {
      title: 'Business Efficiency and Scalability',
      text: 'Adapting our smart business management tool will enhance your business operation and scale your business to new heights. With AI-powered technology, you’ll experience a rapid shift in how tasks are accomplished. In this way, you’ll achieve more in less time while maintaining accuracy.',
      mainImg: img1,
      icons: icon1,
    },
    {
      title: '24/7 Tech Support',
      text: 'Whether you have a technical issue, troubleshooting issue, or any other issue, our dedicated tech support team is available 24/7 to ensure a smooth and hassle-free V-Sync Experience.',
      mainImg: img2,
      icons: icon2,
    },
    {
      title: 'GDPR Complaint',
      text: 'Safeguarding the security of your data is the top priority for us. V-Sync not only values your data security but also complies completely with the exacting regulations set forth by GDPR (General Data Protection Regulation), providing you peace of mind while managing your business efficiently.',
      mainImg: img3,
      icons: icon3,
    },
    {
      title: 'Tailored Subscription Models',
      text: 'We know not all businesses are alike. Our vast range of tailored subscription choices is designed to align precisely with the unique business management needs and requirements. These subscription models have been carefully curated to cater to your specific needs, ensuring that you have access to our tools and features necessary to your business needs.',
      mainImg: img4,
      icons: icon4,
    },
  ];

  return (
    <div className="bg-black w-full h-full py-4 ">
      <div className="text-white mx-auto pt-16">
        <h2 className="text-center text-2xl sm:text-4xl ">
          Advantages of Utilizing V-Sync
        </h2>

        <p className="text-center py-3 text-[15px] sm:text-[22px] text-[#8F8F8F]">
          V-Sync offers comprehensive advantages that benefit your business in
          so many ways.
        </p>
      </div>

      <div className=" mx-auto  w-[95%] sm:w-[90%]">
        <h2 className=" ml-4 bg-gradient-to-r from-[#7C09C3] via-[#86D8F2] to-[#F69400] text-[26px] inline-block text-transparent bg-clip-text py-4 ">
          Advantage
        </h2>

        {contentMatter.map(({ title, text, icons, mainImg }, index) => (
          <div
            key={index}
            className={cx(
              'sm:flex gap-5 p-4 bg-[#121212]/80 rounded-[30px] drop-shadow-lg mb-5 lg:-mr-7  mx-auto',

              {
                'flex-row-reverse lg:mr-0 lg:-ml-8  ': index % 2 !== 0,
              }
            )}
          >
            <div
              className={cx('text-left flex-1  my-auto mx-1 sm:mx-3  ', {
                'text-left sm:text-right': index % 2 !== 0,
              })}
            >
              <p className="text-[#F5F5F5] text-[16px] lg:text-[24px]">
                {title}
              </p>

              <div className="text-[13px] lg:text-[20px] text-[#AAAAAA] py-2 leading-relaxed">
                {text}
              </div>
            </div>

            <div className="relative w-full sm:w-1/2 ">
              <div
                className={cx(
                  'absolute right-[6%] bg-[#361650] p-2 sm:p-3 lg:p-4 rounded-[16px]  top-5',

                  {
                    ' right-[6%] sm:right-[82%] lg:right-[87%] ':
                      index % 2 !== 0,
                  }
                )}
              >
                <img src={icons} alt="tags" className="w-[14px] md:w-[20px]" />
              </div>

              <img src={mainImg} alt="side" className="w-full h-auto" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Utility;
