import React, { useState } from 'react';
import arrow from '../../assets/Icon/arrowIcon.png';
import BackgroundImage from '../../assets/images/aboutUsImage.png';
import webIcon from '../../assets/Icon/webIcon.png';
import sapIcon from '../../assets/Icon/sapIcon.png';
import digitalIcon from '../../assets/Icon/digitalIcon.png';
import mobileIcon from '../../assets/Icon/mobileIcon.png';

const Services = () => {
  const arr = [
    {
      icons: webIcon,
      title: ' Web Development',

      arrows: arrow,
      content: (
        <div className="">
          <div className="bg-[#1B1B1B] p-4 rounded-lg">
            <p className="text-white text-xl md:text-2xl font-medium pb-4">
              Website Development with Latest Technology Like: React js,
              Angular..etc
            </p>
            <p className="text-base md:text-xl font-normal text-white pb-5">
              Web development may be a collaborative effort between department
              rather than the domain of a designated department. There are three
              kinds of web developer specialization:
            </p>
            <ul className="text-base md:text-xl text-white font-medium pb-4 pl-6 list-disc">
              <li className="pb-2 md:pb-4">Front-end developer</li>
              <li className="pb-2 md:pb-4">Back-end developer</li>
              <li className="pb-2 md:pb-4">Full-Stack developer</li>
            </ul>

            <p className="text-base md:text-xl font-normal text-white pb-5">
              Website development typically refers to web markup and coding,
              website development includes all related development tasks, such
              as client-side scripting, server-side scripting, server and
              network security configuration, eCommerce and content management
              system (CMS) development
            </p>
          </div>
        </div>
      ),
    },
    {
      title: ' Mobile App  Development',

      content: (
        <div className="">
          <div className="bg-[#1B1B1B] p-4 rounded-lg">
            <p className="text-white text-xl md:text-2xl font-medium pb-4">
              Mobile App Development with Latest Technology Like: React js,
              Angular..etc
            </p>
            <p className="text-base md:text-xl font-normal text-white pb-5">
              The development of a mobile app typically includes several stages,
              starting with conceptualization and planning. During this phase,
              the app's purpose, target audience, and key features are
              identified. This stage also involves creating wireframes and
              prototypes to visualize the app's user interface and
              functionality.
            </p>
            <ul className="text-base md:text-xl text-white font-medium pb-4 pl-6 list-disc">
              <li className="pb-2 md:pb-4">Front-end developer</li>
              <li className="pb-2 md:pb-4">Back-end developer</li>
              <li className="pb-2 md:pb-4">Full-Stack developer</li>
            </ul>

            <p className="text-base md:text-xl font-normal text-white pb-5">
              Mobile app development requires a combination of technical
              expertise, creativity, and a deep understanding of user needs. It
              is a dynamic and ever-evolving field driven by the constant
              advancements in mobile technology, user expectations, and industry
              trends.
            </p>
          </div>
        </div>
      ),
      icons: mobileIcon,
      arrows: arrow,
    },
    {
      title: 'SAP',
      content: (
        <div className="">
          <div className="bg-[#1B1B1B] p-4 rounded-lg">
            <p className="text-white text-xl md:text-2xl font-medium pb-4">
              SAP is a global software company that specializes in developing
              integrated business solutions, including ERP systems, to help
              organizations streamline operations and make informed decisions.
            </p>
            <p className="text-base md:text-xl font-normal text-white pb-5">
              SAP's primary focus is on developing and delivering integrated
              business software solutions that help organizations streamline
              their operations, enhance productivity, and make better business
              decisions. The company offers a wide range of software products
              and services that cover various business functions, including
              finance, human resources, supply chain management, customer
              relationship management, and more.
            </p>

            <p className="text-base md:text-xl font-normal text-white pb-5">
              The core product of SAP is its ERP system, which serves as a
              central hub for managing and integrating key business processes
              across different departments and functions within an organization.
              This includes modules for financial accounting, procurement, sales
              and distribution, manufacturing, and other operational areas. The
              ERP system enables companies to automate and optimize their
              processes, improve efficiency, and gain real-time insights into
              their business performance.
            </p>
          </div>
        </div>
      ),
      icons: sapIcon,
      arrows: arrow,
    },
    {
      title: 'Digital Marketing',

      content: (
        <div className="">
          <div className="bg-[#1B1B1B] p-4 rounded-lg">
            <p className="text-white text-xl md:text-2xl font-medium pb-4">
              Digital marketing is the practice of utilizing internet-based
              technologies and platforms to promote products, services, or
              brands and engage with a target audience effectively
            </p>
            <p className="text-base md:text-xl font-normal text-white pb-5">
              Digital marketing is a broad term that encompasses various
              strategies and techniques used to promote products, services, or
              brands using digital channels and technologies. It leverages the
              power of the internet and digital devices to reach and engage with
              a target audience
            </p>

            <p className="text-base md:text-xl font-normal text-white pb-5">
              Digital marketing offers numerous advantages, including broader
              reach, targeted audience segmentation, measurable results,
              cost-effectiveness, and the ability to adapt and refine strategies
              based on real-time data. It has become an essential component of
              modern marketing strategies for businesses of all sizes, enabling
              them to connect with their target audience in a more personalized
              and impactful way.
            </p>
          </div>
        </div>
      ),
      icons: digitalIcon,
      arrows: arrow,
    },
  ];
  const [data, setData] = useState(arr[0]);
  const { content = '' } = data || {};

  return (
    <div>
      <div className="min-h-screen pt-28 relative">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div className="absolute inset-0 bg-black opacity-75 mix-blend-multiply" />
        <div className="relative z-10 -mt-14">
          <div className="grid grid-cols-4 w-full lg:px-12 lg:gap-4">
            {arr.map((data, index) => (
              <div
                key={index}
                onClick={() => setData(data)}
                className="cursor-pointer flex flex-col justify-center items-center gap-3 "
              >
                <div className="px-2 flex justify-evenly items-center gap-4">
                  <img src={data.icons} alt="icon" />
                  <div className="lg:block hidden text-[22px] font-semibold text-white ">
                    {data.title}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <img src={data.arrows} alt="arrow-show" />
                </div>
              </div>
            ))}
          </div>
          <div className="pt-5">
            <div className="md:pl-40 md:pb-5 md:block hidden ">
            </div>
            <div className=" mx-2 pb-4 px-5 lg:pr-80 lg:pl-[4rem]">
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
